import { Link, useNavigate } from 'react-router-dom';
import BookNowModal from '../CommonElements/BookNowModal';
import StickyBanner from '../StickyBanner/StickyBanner';
import './index.scss';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../Footer/Footer';
import MIRRoutes from '../../lib/routes';
import BlueFluidBanner from '../CloudSolutions/Elements/Sections/BlueFluidBanner';

export default function Careers() {
    const [showRegionPopup, setShowRegionPopup] = useState(false);
    return (
        <main>
            <Helmet>
                <meta charSet="utf-8" />
                <title>MIR Careers | MIR Digital Solutions</title>
                <meta name="description" content="we connect exceptional IT professionals with transformative opportunities across industries" />
                <link rel="canonical" href={`${window.location.origin}/about-us/case-studies`} />
            </Helmet>

            <StickyBanner />
            <BookNowModal showRegionPopup={showRegionPopup} setShowRegionPopup={setShowRegionPopup} />
            <section className='tw-pt-[80px] tw-pb-[96px] bg-no-repeat' style={{ backgroundImage: 'url(/case-studies/hero-bg.png)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="container mx-auto">
                    <div className='text-center mx-auto tw-max-w-full md:tw-max-w-[900px]'>
                        <h1 className='mir-display-semibold-sm mir-text-neutral-100 tw-mb-[16px]'>Welcome to MIR Digital Career Portal</h1>
                        <h4 className='mir-text-neutral-400 mir-p-md-regular'>
                            At MIR Digital, we connect IT professionals with transformative opportunities and deliver innovative technology solutions to drive impact across diverse industries.
                        </h4>
                    </div>
                </div>
            </section>
            <section className='tw-pt-[64px] tw-pb-[72px]' data-section id='section-ca-2'>
                <div className="container mx-auto">
                    <h2 className='mir-neutral-800 mir-h3-semibold tw-mb-[24px] mir-text-primary-500 mb-5'>Why Join MIR Digital?</h2>
                    <div className="text-center">
                        <div className='tw-grid tw-cols-1 md:tw-grid-cols-4 tw-gap-x-[32px] tw-gap-y-[56px]'>
                            {
                                [
                                    { img: '/careers/why/1.svg', heading: 'Industry Impact', desc: 'Work on high-profile projects and be part of teams that solve critical business challenges and shape the future of digital transformation in finance, telecom, mining, retail, non-profit and many other sectors.' },
                                    { img: '/careers/why/2.svg', heading: 'Technology Partnership Advantage', desc: 'Gain hands-on experience with the latest tools and platforms, contributing to transformative client solutions.' },
                                    { img: '/careers/why/3.svg', heading: 'Career Growth', desc: 'Build your expertise through mentorship, training and certifications, and skill development tailored to your career aspirations.' },
                                    { img: '/careers/why/4.svg', heading: 'Inclusive & Collaborative Culture', desc: 'Being an equal opportunity employer, MIR Digital values diversity and fosters an environment where innovation and collaboration thrive.' }
                                ].map((item, index) => {
                                    return <div key={`intro-features-index-${index}`} className='text-start'>
                                        <img src={item.img} alt={item.heading} className='careers-why-icons tw-mb-[24px]' />
                                        <h5 className='mir-h6-semibold mir-text-neutral-800 tw-mb-[12px]'>{item.heading}</h5>
                                        <p className='mir-text-neutral-600 mir-p-rg-regular tw-max-w-full md:tw-max-w-[384px]'>{item.desc}</p>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </section>
            <section className='int-sec-9 career-last-sec'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-7'>
                            <div className='int-sec-9-header tw-mb-[36px]'>
                                Explore Career Opportunities
                                {/* <span className='int-sec-9-header-start'>Build</span> the Future Across Industries with MIR */}
                            </div>
                            <p className='careers-last-sec-desc'>
                                Are you ready to make an impact? Join MIR Digital and take your career to the next level by contributing to innovative projects that transform industries.
                            </p>
                            <a style={{ cursor: "pointer" }} className='mir-btn-primary mir-btn-md tw-mb-[32px]' onClick={(e) => { window.location.href = MIRRoutes.CareersOpenings }}>View Open Positions</a>
                        </div>
                    </div>
                </div>
            </section>
            <section className='tw-pt-[36px] tw-pb-[96px] mir-bg-neutral-100' id='careers-sec-3'>
                <div className="container mx-auto">
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 md:tw-gap-x-[90px]">
                        <div className='tw-flex tw-items-center tw-justify-center'>
                            <img src="/careers/bg-left.jpg" alt="" className='tw-w-full tw-max-w-full career-bg-left-img' />
                        </div>
                        <div>
                            <h4 className='mir-p-overline-lg mir-text-primary-500 tw-mb-[16px]'>Perks & Benefits</h4>
                            <h3 className='mir-h3-semibold mir-text-neutral-800 tw-mb-[32px]'>We believe in empowering our teams with the tools and resources to succeed</h3>
                            <ul className='tw-p-0'>
                                {
                                    [
                                        'Competitive salaries and performance incentives',
                                        'Comprehensive health, dental, and vision insurance (country specific)',
                                        'Paid time off, including vacation, and personal days',
                                        'Support for Microsoft and other professional certifications',
                                        'Flexible and hybrid work arrangements',
                                        'Opportunities to work on transformative projects',
                                        'Access to the latest tools and technologies in Microsoft’s ecosystem',
                                    ].map((item, index) => {
                                        return <li className='tw-flex tw-items-start tw-justify-start tw-mb-[24px]' key={`advantage-item-${index}`}>
                                            <svg className='tw-min-w-[24px] tw-mr-[16px]' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="24" height="24" rx="12" fill="#D1E5FF" />
                                                <path d="M18.6099 8.34847L10.1905 16.7678C10.1172 16.8414 10.03 16.8998 9.93408 16.9397C9.83814 16.9795 9.73528 17 9.63139 17C9.52751 17 9.42464 16.9795 9.3287 16.9397C9.23276 16.8998 9.14562 16.8414 9.07229 16.7678L5.38882 13.0844C5.31539 13.0109 5.25715 12.9238 5.21742 12.8279C5.17768 12.7319 5.15723 12.6291 5.15723 12.5253C5.15723 12.4214 5.17768 12.3186 5.21742 12.2227C5.25715 12.1268 5.31539 12.0396 5.38882 11.9662C5.46224 11.8927 5.5494 11.8345 5.64533 11.7948C5.74126 11.755 5.84408 11.7346 5.94792 11.7346C6.05175 11.7346 6.15457 11.755 6.2505 11.7948C6.34643 11.8345 6.43359 11.8927 6.50702 11.9662L9.63205 15.0912L17.493 7.23159C17.6413 7.0833 17.8424 7 18.0521 7C18.2618 7 18.4629 7.0833 18.6112 7.23159C18.7595 7.37987 18.8428 7.58098 18.8428 7.79069C18.8428 8.00039 18.7595 8.2015 18.6112 8.34978L18.6099 8.34847Z" fill="#1D68F0" />
                                            </svg>
                                            <h5 className='mir-p-rg-regular mir-text-neutral-800'>{item}</h5>
                                        </li>
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className='int-sec-3 careers-sec-4'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8 offset-lg-2 text-center'>
                            <h4 className='mir-h3-semibold tw-mb-[16px]'>Life at MIR Digital </h4>
                            <p className='mb-5'>Here’s what our employees say about working with us:</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="32" viewBox="0 0 50 32" fill="none">
                                <path d="M39.5198 32C37.9314 32 36.4629 31.7453 35.1143 31.236C33.7956 30.7266 32.6568 29.9925 31.6978 29.0337C30.7388 28.0449 29.9896 26.8614 29.4501 25.4831C28.9107 24.1049 28.6409 22.5468 28.6409 20.809C28.6409 16.8839 29.9746 13.1386 32.6418 9.57303C35.3391 5.97753 39.25 2.78652 44.3748 0L49.3197 7.05618C47.9711 7.77528 46.7723 8.4794 45.7234 9.16854C44.7044 9.85768 43.8054 10.5618 43.0262 11.2809C42.2769 12 41.6176 12.7491 41.0482 13.5281C40.5087 14.2772 40.0143 15.0861 39.5647 15.9551C41.0332 15.9551 42.3519 16.1648 43.5206 16.5843C44.7194 17.0037 45.7384 17.588 46.5775 18.3371C47.4466 19.0562 48.1059 19.9101 48.5555 20.8989C49.035 21.8577 49.2747 22.8914 49.2747 24C49.2747 25.1086 49.035 26.1573 48.5555 27.1461C48.1059 28.1049 47.4466 28.9438 46.5775 29.6629C45.7384 30.382 44.7194 30.9513 43.5206 31.3708C42.3219 31.7903 40.9883 32 39.5198 32ZM11.5585 32C9.97014 32 8.50165 31.7453 7.15303 31.236C5.83439 30.7266 4.69556 29.9925 3.73655 29.0337C2.77753 28.0449 2.0283 26.8614 1.48886 25.4831C0.94941 24.1049 0.679688 22.5468 0.679688 20.809C0.679688 16.8839 2.01332 13.1386 4.68057 9.57303C7.3778 5.97753 11.2888 2.78652 16.4135 0L21.3584 7.05618C20.0098 7.77528 18.8111 8.4794 17.7621 9.16854C16.7432 9.85768 15.8441 10.5618 15.0649 11.2809C14.3157 12 13.6563 12.7491 13.0869 13.5281C12.5475 14.2772 12.053 15.0861 11.6035 15.9551C13.0719 15.9551 14.3906 16.1648 15.5594 16.5843C16.7582 17.0037 17.7771 17.588 18.6162 18.3371C19.4854 19.0562 20.1447 19.9101 20.5942 20.8989C21.0737 21.8577 21.3135 22.8914 21.3135 24C21.3135 25.1086 21.0737 26.1573 20.5942 27.1461C20.1447 28.1049 19.4854 28.9438 18.6162 29.6629C17.7771 30.382 16.7582 30.9513 15.5594 31.3708C14.3606 31.7903 13.027 32 11.5585 32Z" fill="#EEEEF2" />
                            </svg>
                            <div className='careers-sec-4-content'>
                                MIR’s projects with Microsoft technologies are some of the most rewarding and exciting I’ve worked on. You feel like you’re truly shaping the future.
                            </div>
                            <div className='careers-sec-4-content-2'>
                                <Link class="mir-btn-secondary mir-btn-md mir-text-primary-500 mir-bg-white" to={MIRRoutes.MIRCulture}><span class="tw-mr-[12px] mir-p-rg-medium">Read more</span><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.3172 10.4422L11.6922 16.0672C11.5749 16.1844 11.4159 16.2503 11.25 16.2503C11.0841 16.2503 10.9251 16.1844 10.8078 16.0672C10.6905 15.9499 10.6247 15.7908 10.6247 15.625C10.6247 15.4591 10.6905 15.3001 10.8078 15.1828L15.3664 10.625H3.125C2.95924 10.625 2.80027 10.5591 2.68306 10.4419C2.56585 10.3247 2.5 10.1657 2.5 9.99998C2.5 9.83422 2.56585 9.67525 2.68306 9.55804C2.80027 9.44083 2.95924 9.37498 3.125 9.37498H15.3664L10.8078 4.81717C10.6905 4.69989 10.6247 4.54083 10.6247 4.37498C10.6247 4.20913 10.6905 4.05007 10.8078 3.93279C10.9251 3.81552 11.0841 3.74963 11.25 3.74963C11.4159 3.74963 11.5749 3.81552 11.6922 3.93279L17.3172 9.55779C17.3753 9.61584 17.4214 9.68477 17.4529 9.76064C17.4843 9.83652 17.5005 9.91785 17.5005 9.99998C17.5005 10.0821 17.4843 10.1634 17.4529 10.2393C17.4214 10.3152 17.3753 10.3841 17.3172 10.4422Z" fill="#1D68F0"></path></svg></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='career-last-sec text-center'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8 offset-lg-2'>
                            <div className='mir-h3-semibold mir-text-neutral-800 tw-mb-[36px]'>
                                <span className='int-sec-9-header-start'>Build</span> the Future Across Industries with MIR
                            </div>
                            <p className='mir-text-neutral-400 mir-p-md-regular tw-mb-[16px]'>
                                At MIR Digital, you’re not just starting a job—you’re joining a team committed to transforming industries with technology, innovation, and expertise. Let’s build the future together.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </main>
    );
}