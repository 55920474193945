import React, { useState } from 'react'

import MIRRoutes from '../../lib/routes';
import { Link, useNavigate } from 'react-router-dom';
import { ContactForm } from '../CloudSolutions/Elements/Sections/ContactSection';
import { Banner } from '../HomeComponent/Banner';
import CountUp from 'react-countup';
import { Helmet } from 'react-helmet';
import { Suspense } from 'react';

const StickyBanner = React.lazy(() => import('../StickyBanner/StickyBanner'));
const Footer = React.lazy(() => import('../Footer/Footer'));

const BookNowModal = React.lazy(() => import('../CommonElements/BookNowModal'));
const GalaxyIcons = React.lazy(() => import('../HomeElements/GalaxyIcons'));
const HomeCarousel = React.lazy(() => import('../HomeCarousel'));


export default function HomeComponentNew() {

    const [showRegionPopup, setShowRegionPopup] = useState(false);
    const navigate = useNavigate();

    return (
        <main style={{
            maxWidth: '100%', width: '100%', overflowX: 'hidden'
        }}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Microsoft Dynamics 365 & Power Platform Experts | Canada & UAE | MIR Digital Solutions</title>
                <meta name="description" content="Unlock business potential with Microsoft Certified excellence in Dynamics 365 ERP, CRM, Sales, SharePoint, Business Central, Supply Chain Management & Power Platform" />
                <link rel="canonical" href={`${window.location.origin}`} />
            </Helmet>

            <Suspense>
                <StickyBanner />
            </Suspense>
            <Suspense>
                <BookNowModal showRegionPopup={showRegionPopup} setShowRegionPopup={setShowRegionPopup} />
            </Suspense>

            <section className='tw-pt-[120px] tw-pb-[200px] tw-relative' style={{ backgroundColor: "black" }}>
                <video autoPlay={true} playsInline={true} muted={true} loop={true} className=' tw-absolute tw-z-0 tw-inset-0'>
                    {/* <source src="https://res.cloudinary.com/dln6bkc9i/video/upload/v1695413534/Hero_section_Video_loop_bgsmuz.mp4" type="video/mp4" /> */}
                    <source src="/hero-video.mp4" className='tw-transform tw-rotate-x-180 tw-select-none tw-pointer-events-none' type="video/mp4" />
                </video>
                <div className="container mx-auto tw-relative tw-z-10">
                    <h1 className='mir-display-semibold-lg mir-text-neutral-100 tw-max-w-full md:tw-max-w-[780px]'>Unlock Your Business Potential With <span className='tw-hidden'>Industry Certified Excellence</span></h1>
                    <div style={{ background: 'url(/new-home/text-gradient-bg.png)', backgroundClip: 'text' }} className='tw-mb-[36px] mir-display-semibold-lg tw-text-transparent'>Industry Certified Excellence</div>
                    <p className='mir-text-neutral-300 mir-p-lg-medium tw-max-w-full md:tw-max-w-[531px]'>Microsoft Dynamics 365, AI, Copilot, Azure, Power Platform and AWS Experts at Your Service</p>
                </div>
            </section>
            <section className='tw-grid tw-grid-cols-1 md:tw-grid-cols-3'>
                <a href='tel:+18664373812' style={{ borderRight: '1px solid #00319C', borderBottom: '1px solid #00319C' }} className='tw-flex tw-py-[32px] mir-p-rg-medium tw-justify-center tw-items-center mir-text-neutral-100 mir-bg-primary-500 hover:!tw-bg-[#00319C] tw-transition tw-cursor-pointer'>
                    <svg width="20" height="21" viewBox="0 0 20 21" className='tw-mr-[8px]' fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.3727 12.8795L13.6922 11.2303L13.682 11.2256C13.491 11.1439 13.2825 11.1111 13.0756 11.1302C12.8687 11.1493 12.6698 11.2197 12.4969 11.335C12.4765 11.3484 12.457 11.3631 12.4383 11.3787L10.5367 12.9998C9.33204 12.4147 8.08829 11.1803 7.50313 9.99125L9.12657 8.06078C9.14219 8.04125 9.15704 8.02172 9.1711 8.00062C9.28394 7.8282 9.35239 7.63056 9.37039 7.42529C9.38838 7.22002 9.35534 7.01348 9.27423 6.82406V6.81469L7.62032 3.12797C7.51309 2.88052 7.3287 2.67438 7.09468 2.54034C6.86067 2.4063 6.58958 2.35153 6.32188 2.38422C5.26326 2.52352 4.29155 3.04341 3.58824 3.84679C2.88492 4.65017 2.49809 5.6821 2.50001 6.74984C2.50001 12.953 7.54688 17.9998 13.75 17.9998C14.8177 18.0018 15.8497 17.6149 16.6531 16.9116C17.4564 16.2083 17.9763 15.2366 18.1156 14.178C18.1484 13.9104 18.0937 13.6393 17.9598 13.4053C17.8259 13.1713 17.62 12.9869 17.3727 12.8795ZM13.75 16.7498C11.0987 16.7469 8.55687 15.6924 6.68214 13.8177C4.8074 11.943 3.7529 9.40112 3.75001 6.74984C3.74707 5.98694 4.02192 5.24906 4.52324 4.67399C5.02456 4.09892 5.71806 3.72599 6.47423 3.62484C6.47392 3.62796 6.47392 3.6311 6.47423 3.63422L8.11485 7.30609L6.50001 9.2389C6.48362 9.25776 6.46873 9.27788 6.45548 9.29906C6.33791 9.47947 6.26894 9.68718 6.25525 9.90208C6.24157 10.117 6.28362 10.3318 6.37735 10.5256C7.08516 11.9733 8.54376 13.4209 10.007 14.128C10.2023 14.2208 10.4184 14.2614 10.634 14.2458C10.8497 14.2302 11.0576 14.1589 11.2375 14.0389C11.2576 14.0254 11.2769 14.0108 11.2953 13.9952L13.1945 12.3748L16.8664 14.0194C16.8664 14.0194 16.8727 14.0194 16.875 14.0194C16.7751 14.7766 16.4027 15.4715 15.8275 15.9741C15.2524 16.4766 14.5138 16.7524 13.75 16.7498Z" fill="#EEEEF2" />
                    </svg>
                    <span>Call North American Representative</span>
                </a>
                <a aria-label="tel" href='tel:+971528775716' style={{ borderRight: '1px solid #00319C', borderBottom: '1px solid #00319C' }} className='tw-flex tw-py-[32px] mir-p-rg-medium tw-justify-center tw-items-center mir-text-neutral-100 mir-bg-primary-500 hover:!tw-bg-[#00319C] tw-transition tw-cursor-pointer'>
                    <svg width="20" height="21" viewBox="0 0 20 21" className='tw-mr-[8px]' fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.3727 12.8795L13.6922 11.2303L13.682 11.2256C13.491 11.1439 13.2825 11.1111 13.0756 11.1302C12.8687 11.1493 12.6698 11.2197 12.4969 11.335C12.4765 11.3484 12.457 11.3631 12.4383 11.3787L10.5367 12.9998C9.33204 12.4147 8.08829 11.1803 7.50313 9.99125L9.12657 8.06078C9.14219 8.04125 9.15704 8.02172 9.1711 8.00062C9.28394 7.8282 9.35239 7.63056 9.37039 7.42529C9.38838 7.22002 9.35534 7.01348 9.27423 6.82406V6.81469L7.62032 3.12797C7.51309 2.88052 7.3287 2.67438 7.09468 2.54034C6.86067 2.4063 6.58958 2.35153 6.32188 2.38422C5.26326 2.52352 4.29155 3.04341 3.58824 3.84679C2.88492 4.65017 2.49809 5.6821 2.50001 6.74984C2.50001 12.953 7.54688 17.9998 13.75 17.9998C14.8177 18.0018 15.8497 17.6149 16.6531 16.9116C17.4564 16.2083 17.9763 15.2366 18.1156 14.178C18.1484 13.9104 18.0937 13.6393 17.9598 13.4053C17.8259 13.1713 17.62 12.9869 17.3727 12.8795ZM13.75 16.7498C11.0987 16.7469 8.55687 15.6924 6.68214 13.8177C4.8074 11.943 3.7529 9.40112 3.75001 6.74984C3.74707 5.98694 4.02192 5.24906 4.52324 4.67399C5.02456 4.09892 5.71806 3.72599 6.47423 3.62484C6.47392 3.62796 6.47392 3.6311 6.47423 3.63422L8.11485 7.30609L6.50001 9.2389C6.48362 9.25776 6.46873 9.27788 6.45548 9.29906C6.33791 9.47947 6.26894 9.68718 6.25525 9.90208C6.24157 10.117 6.28362 10.3318 6.37735 10.5256C7.08516 11.9733 8.54376 13.4209 10.007 14.128C10.2023 14.2208 10.4184 14.2614 10.634 14.2458C10.8497 14.2302 11.0576 14.1589 11.2375 14.0389C11.2576 14.0254 11.2769 14.0108 11.2953 13.9952L13.1945 12.3748L16.8664 14.0194C16.8664 14.0194 16.8727 14.0194 16.875 14.0194C16.7751 14.7766 16.4027 15.4715 15.8275 15.9741C15.2524 16.4766 14.5138 16.7524 13.75 16.7498Z" fill="#EEEEF2" />
                    </svg>
                    <span>Call Middle Eastern Representative</span>
                </a>
                <div onClick={() => { setShowRegionPopup(true) }} style={{ borderRight: '1px solid #00319C', borderBottom: '1px solid #00319C' }} className='tw-flex tw-py-[32px] mir-p-rg-medium tw-justify-center tw-items-center mir-text-neutral-100 mir-bg-primary-500 hover:!tw-bg-[#00319C] tw-transition tw-cursor-pointer'>
                    <svg width="20" height="21" className='tw-mr-[8px]' viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.5 4.25H2.5C2.33424 4.25 2.17527 4.31585 2.05806 4.43306C1.94085 4.55027 1.875 4.70924 1.875 4.875V15.5C1.875 15.8315 2.0067 16.1495 2.24112 16.3839C2.47554 16.6183 2.79348 16.75 3.125 16.75H16.875C17.2065 16.75 17.5245 16.6183 17.7589 16.3839C17.9933 16.1495 18.125 15.8315 18.125 15.5V4.875C18.125 4.70924 18.0592 4.55027 17.9419 4.43306C17.8247 4.31585 17.6658 4.25 17.5 4.25ZM15.893 5.5L10 10.9023L4.10703 5.5H15.893ZM16.875 15.5H3.125V6.29609L9.57734 12.2109C9.69265 12.3168 9.84348 12.3755 10 12.3755C10.1565 12.3755 10.3074 12.3168 10.4227 12.2109L16.875 6.29609V15.5Z" fill="#EEEEF2" />
                    </svg>
                    <span>Meet with a Microsoft Expert</span>
                </div>
            </section>

            <Suspense>
                <GalaxyIcons />
            </Suspense>

            <section className='tw-pt-[96px] tw-pb-[96px]'>
                <div className="container mx-auto text-center">
                    <h4 className='mir-text-neutral-500 mir-p-overline-lg tw-mb-[24px]'>Trust the experts with <span className='mir-text-primary-500'>Microsoft Badge</span></h4>
                    <img height="auto" width="auto" src="/new-home/microsoft-solutions-partner.png" alt="microsoft-solutions-partner" />
                </div>
            </section>
            <hr style={{
                width: '70%',
                margin: '0 auto',
                borderTop: '1px solid #ccc',
            }} />

            <section className='tw-pt-[96px] tw-pb-[48px]'>
                <div className="container mx-auto">
                    <div className="text-center">
                        <h2 className='mir-text-neutral-800 mir-display-semibold-sm tw-mb-[24px]'>Our Business Solutions</h2>
                        <p className='mx-auto mir-p-md-regular mir-text-neutral-600 tw-mb-[64px] tw-max-w-full md:tw-max-w-[698px]'> Leading global cloud services provider with over 7 years of expertise in Microsoft solutions. We
                            help organizations of all sizes optimize technology, maintain compliance, and protect critical
                            data</p>
                    </div>
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-[32px]">
                        {
                            [
                                { number: '01/', title: 'Cloud & Virtualization Services', href: MIRRoutes.CloudServices },
                                { number: '02/', title: 'Enterprise Resource Planning (ERP)', href: MIRRoutes.ERP },
                                { number: '03/', title: 'Customer Relationship Management (CRM)', href: MIRRoutes.CRM },
                                { number: '04/', title: 'Business Intelligence & Data Analytics (BI)', href: MIRRoutes.BusinessIntelligence },
                                { number: '05/', title: 'Project Rescue', href: MIRRoutes.ProjectRescue },
                                { number: '06/', title: 'Staffing Solutions', href: MIRRoutes.StaffingSolutions },
                            ].map((item, index) => {
                                return <div key={`service-item-${index}`} className='tw-min-h-[204px] mir-bg-neutral-100 tw-px-[32px] tw-py-[24px] tw-rounded-[8px] tw-flex tw-flex-col tw-justify-between' style={{ border: '1.5px solid #EEEEF2' }}>
                                    <div>
                                        <div className='mir-text-neutral-400 mir-p-overline-lg tw-mb-[12px]'>{item.number}</div>
                                        <h4 className='mir-text-neutral-800 mir-h6-semibold tw-max-w-full md:tw-max-w-[320px]'>{item.title}</h4>
                                    </div>
                                    <div>
                                        <Link className='mir-text-primary-500 mir-p-rg-medium' to={item.href}>{`Explore more ->`}</Link>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </section>

            <section className='mir-bg-neutral-100'>
                <div className="tw-py-[96px] container mx-auto" style={{ borderBottom: '1px solid #D6DBE0' }}>
                    <h3 className='mir-text-neutral-900 mir-h3-semibold tw-mb-[80px]'><span className='mir-text-neutral-400'>Does your on-going project need to be rescued?</span> <br /> Let’s get you back on track.</h3>
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-[32px]">
                        <div>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.8472 14.8554L16.4306 12.8764L16.4184 12.8707C16.1892 12.7727 15.939 12.7333 15.6907 12.7562C15.4424 12.7792 15.2037 12.8636 14.9963 13.002C14.9718 13.0181 14.9484 13.0357 14.9259 13.0545L12.6441 14.9998C11.1984 14.2976 9.70595 12.8164 9.00376 11.3895L10.9519 9.07293C10.9706 9.0495 10.9884 9.02606 11.0053 9.00075C11.1407 8.79384 11.2229 8.55667 11.2445 8.31035C11.2661 8.06402 11.2264 7.81618 11.1291 7.58887V7.57762L9.14438 3.15356C9.0157 2.85662 8.79444 2.60926 8.51362 2.44841C8.2328 2.28756 7.9075 2.22184 7.58626 2.26106C6.31592 2.42822 5.14986 3.05209 4.30588 4.01615C3.4619 4.98021 2.99771 6.21852 3.00001 7.49981C3.00001 14.9436 9.05626 20.9998 16.5 20.9998C17.7813 21.0021 19.0196 20.5379 19.9837 19.6939C20.9477 18.85 21.5716 17.6839 21.7388 16.4136C21.7781 16.0924 21.7125 15.7672 21.5518 15.4864C21.3911 15.2056 21.144 14.9843 20.8472 14.8554ZM16.5 19.4998C13.3185 19.4963 10.2682 18.2309 8.01856 15.9813C5.76888 13.7316 4.50348 10.6813 4.50001 7.49981C4.49648 6.58433 4.82631 5.69887 5.42789 5.00879C6.02947 4.3187 6.86167 3.87118 7.76907 3.74981C7.7687 3.75355 7.7687 3.75732 7.76907 3.76106L9.73782 8.16731L7.80001 10.4867C7.78034 10.5093 7.76247 10.5335 7.74657 10.5589C7.60549 10.7754 7.52273 11.0246 7.5063 11.2825C7.48988 11.5404 7.54035 11.7981 7.65282 12.0307C8.5022 13.7679 10.2525 15.5051 12.0084 16.3536C12.2428 16.465 12.502 16.5137 12.7608 16.495C13.0196 16.4762 13.2692 16.3907 13.485 16.2467C13.5091 16.2305 13.5322 16.2129 13.5544 16.1942L15.8334 14.2498L20.2397 16.2232C20.2397 16.2232 20.2472 16.2232 20.25 16.2232C20.1301 17.1319 19.6833 17.9658 18.9931 18.5689C18.3028 19.1719 17.4166 19.5029 16.5 19.4998Z" fill="#1D68F0" />
                            </svg>
                            <h4 className='tw-pt-[16px] mir-text-neutral-700 mir-h6-semibold tw-mb-[12px]'>Talk to an NA Representative</h4>
                            <p className='mir-text-neutral-600 mir-p-rg-regular tw-mb-[16px]'>Contact our dedicated representative for tailored support in North America.</p>
                            <a href="tel:+18664373812" aria-label="tel" className='tw-flex tw-justify-start tw-items-center mir-text-primary-500 mir-p-md-semibold'>
                                <span className='tw-mr-[12px]'>Connect with us</span>
                                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.3172 10.9425L11.6922 16.5675C11.5749 16.6848 11.4159 16.7507 11.25 16.7507C11.0841 16.7507 10.9251 16.6848 10.8078 16.5675C10.6905 16.4503 10.6247 16.2912 10.6247 16.1253C10.6247 15.9595 10.6905 15.8004 10.8078 15.6832L15.3664 11.1253H3.125C2.95924 11.1253 2.80027 11.0595 2.68306 10.9423C2.56585 10.8251 2.5 10.6661 2.5 10.5003C2.5 10.3346 2.56585 10.1756 2.68306 10.0584C2.80027 9.9412 2.95924 9.87535 3.125 9.87535H15.3664L10.8078 5.31753C10.6905 5.20026 10.6247 5.0412 10.6247 4.87535C10.6247 4.7095 10.6905 4.55044 10.8078 4.43316C10.9251 4.31588 11.0841 4.25 11.25 4.25C11.4159 4.25 11.5749 4.31588 11.6922 4.43316L17.3172 10.0582C17.3753 10.1162 17.4214 10.1851 17.4529 10.261C17.4843 10.3369 17.5005 10.4182 17.5005 10.5003C17.5005 10.5825 17.4843 10.6638 17.4529 10.7397C17.4214 10.8156 17.3753 10.8845 17.3172 10.9425Z" fill="#1D68F0" />
                                </svg>
                            </a>
                        </div>
                        <div>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.8472 14.8554L16.4306 12.8764L16.4184 12.8707C16.1892 12.7727 15.939 12.7333 15.6907 12.7562C15.4424 12.7792 15.2037 12.8636 14.9963 13.002C14.9718 13.0181 14.9484 13.0357 14.9259 13.0545L12.6441 14.9998C11.1984 14.2976 9.70595 12.8164 9.00376 11.3895L10.9519 9.07293C10.9706 9.0495 10.9884 9.02606 11.0053 9.00075C11.1407 8.79384 11.2229 8.55667 11.2445 8.31035C11.2661 8.06402 11.2264 7.81618 11.1291 7.58887V7.57762L9.14438 3.15356C9.0157 2.85662 8.79444 2.60926 8.51362 2.44841C8.2328 2.28756 7.9075 2.22184 7.58626 2.26106C6.31592 2.42822 5.14986 3.05209 4.30588 4.01615C3.4619 4.98021 2.99771 6.21852 3.00001 7.49981C3.00001 14.9436 9.05626 20.9998 16.5 20.9998C17.7813 21.0021 19.0196 20.5379 19.9837 19.6939C20.9477 18.85 21.5716 17.6839 21.7388 16.4136C21.7781 16.0924 21.7125 15.7672 21.5518 15.4864C21.3911 15.2056 21.144 14.9843 20.8472 14.8554ZM16.5 19.4998C13.3185 19.4963 10.2682 18.2309 8.01856 15.9813C5.76888 13.7316 4.50348 10.6813 4.50001 7.49981C4.49648 6.58433 4.82631 5.69887 5.42789 5.00879C6.02947 4.3187 6.86167 3.87118 7.76907 3.74981C7.7687 3.75355 7.7687 3.75732 7.76907 3.76106L9.73782 8.16731L7.80001 10.4867C7.78034 10.5093 7.76247 10.5335 7.74657 10.5589C7.60549 10.7754 7.52273 11.0246 7.5063 11.2825C7.48988 11.5404 7.54035 11.7981 7.65282 12.0307C8.5022 13.7679 10.2525 15.5051 12.0084 16.3536C12.2428 16.465 12.502 16.5137 12.7608 16.495C13.0196 16.4762 13.2692 16.3907 13.485 16.2467C13.5091 16.2305 13.5322 16.2129 13.5544 16.1942L15.8334 14.2498L20.2397 16.2232C20.2397 16.2232 20.2472 16.2232 20.25 16.2232C20.1301 17.1319 19.6833 17.9658 18.9931 18.5689C18.3028 19.1719 17.4166 19.5029 16.5 19.4998Z" fill="#1D68F0" />
                            </svg>
                            <h4 className='tw-pt-[16px] mir-text-neutral-700 mir-h6-semibold tw-mb-[12px]'>Talk to an MEA Representative</h4>
                            <p className='mir-text-neutral-600 mir-p-rg-regular tw-mb-[16px]'>Connect with our MEA representative for personalized assistance in the Middle East_ region.</p>
                            <a href="tel:+971585129979" className='tw-flex tw-justify-start tw-items-center mir-text-primary-500 mir-p-md-semibold'>
                                <span className='tw-mr-[12px]'>Connect with us</span>
                                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.3172 10.9425L11.6922 16.5675C11.5749 16.6848 11.4159 16.7507 11.25 16.7507C11.0841 16.7507 10.9251 16.6848 10.8078 16.5675C10.6905 16.4503 10.6247 16.2912 10.6247 16.1253C10.6247 15.9595 10.6905 15.8004 10.8078 15.6832L15.3664 11.1253H3.125C2.95924 11.1253 2.80027 11.0595 2.68306 10.9423C2.56585 10.8251 2.5 10.6661 2.5 10.5003C2.5 10.3346 2.56585 10.1756 2.68306 10.0584C2.80027 9.9412 2.95924 9.87535 3.125 9.87535H15.3664L10.8078 5.31753C10.6905 5.20026 10.6247 5.0412 10.6247 4.87535C10.6247 4.7095 10.6905 4.55044 10.8078 4.43316C10.9251 4.31588 11.0841 4.25 11.25 4.25C11.4159 4.25 11.5749 4.31588 11.6922 4.43316L17.3172 10.0582C17.3753 10.1162 17.4214 10.1851 17.4529 10.261C17.4843 10.3369 17.5005 10.4182 17.5005 10.5003C17.5005 10.5825 17.4843 10.6638 17.4529 10.7397C17.4214 10.8156 17.3753 10.8845 17.3172 10.9425Z" fill="#1D68F0" />
                                </svg>
                            </a>
                        </div>
                        <div>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21 4.5H3C2.80109 4.5 2.61032 4.57902 2.46967 4.71967C2.32902 4.86032 2.25 5.05109 2.25 5.25V18C2.25 18.3978 2.40804 18.7794 2.68934 19.0607C2.97064 19.342 3.35218 19.5 3.75 19.5H20.25C20.6478 19.5 21.0294 19.342 21.3107 19.0607C21.592 18.7794 21.75 18.3978 21.75 18V5.25C21.75 5.05109 21.671 4.86032 21.5303 4.71967C21.3897 4.57902 21.1989 4.5 21 4.5ZM19.0716 6L12 12.4828L4.92844 6H19.0716ZM20.25 18H3.75V6.95531L11.4928 14.0531C11.6312 14.1801 11.8122 14.2506 12 14.2506C12.1878 14.2506 12.3688 14.1801 12.5072 14.0531L20.25 6.95531V18Z" fill="#1D68F0" />
                            </svg>
                            <h4 className='tw-pt-[16px] mir-text-neutral-700 mir-h6-semibold tw-mb-[12px]'>Meet with a Microsoft Expert</h4>
                            <p className='mir-text-neutral-600 mir-p-rg-regular tw-mb-[16px]'>Schedule a meeting with a Microsoft expert for personalized advice and technology optimization.</p>
                            <div onClick={() => { setShowRegionPopup(true) }} style={{ cursor: 'pointer' }} className='tw-flex tw-justify-start tw-items-center mir-text-primary-500 mir-p-md-semibold'>
                                <span className='tw-mr-[12px]'>Schedule a meeting</span>
                                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.3172 10.9425L11.6922 16.5675C11.5749 16.6848 11.4159 16.7507 11.25 16.7507C11.0841 16.7507 10.9251 16.6848 10.8078 16.5675C10.6905 16.4503 10.6247 16.2912 10.6247 16.1253C10.6247 15.9595 10.6905 15.8004 10.8078 15.6832L15.3664 11.1253H3.125C2.95924 11.1253 2.80027 11.0595 2.68306 10.9423C2.56585 10.8251 2.5 10.6661 2.5 10.5003C2.5 10.3346 2.56585 10.1756 2.68306 10.0584C2.80027 9.9412 2.95924 9.87535 3.125 9.87535H15.3664L10.8078 5.31753C10.6905 5.20026 10.6247 5.0412 10.6247 4.87535C10.6247 4.7095 10.6905 4.55044 10.8078 4.43316C10.9251 4.31588 11.0841 4.25 11.25 4.25C11.4159 4.25 11.5749 4.31588 11.6922 4.43316L17.3172 10.0582C17.3753 10.1162 17.4214 10.1851 17.4529 10.261C17.4843 10.3369 17.5005 10.4182 17.5005 10.5003C17.5005 10.5825 17.4843 10.6638 17.4529 10.7397C17.4214 10.8156 17.3753 10.8845 17.3172 10.9425Z" fill="#1D68F0" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container mx-auto tw-pt-[96px]'>
                    <div className="text-center">
                        <h4 className='mir-text-primary-500 mir-p-overline-lg tw-mb-[16px]'>WE ARE A LEADING MICROSOFT PARTNER</h4>
                        <h3 className='tw-max-w-full md:tw-max-w-[730px] tw-mb-[64px] mir-h4-semibold mir-text-neutral-900 mx-auto'>Trusted by businesses of all sizes, across North America, Middle East and Africa.</h3>
                    </div>
                </div>
                <Suspense>
                    <Banner />
                </Suspense>
                <div className='tw-pb-[72px]'></div>
                <img height="auto" width="auto" src="/globe-vector.png" className='tw-w-full mx-auto' alt="globe-vector" />
            </section>

            <section className='tw-py-[120px]'>
                <div className="container mx-auto">
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-4">
                        <div className='md:tw-col-span-1 tw-my-[32px]'>
                            <div className="tw-flex tw-justify-center md:tw-justify-end tw-mb-[32px]">
                                <img className='tw-mr-[32px]' height="auto" width="auto" src="/partners/left/1.png" alt="partner-1" />
                                <div className='partner-image-border'>
                                    <img height="auto" className='partner-image-media' width="auto" src="/partners/left/7.jpg" alt="partner-1" />
                                </div>
                            </div>
                            <div className="tw-flex tw-justify-center md:tw-justify-end tw-mb-[32px]">
                                <img height="auto" width="auto" src="/partners/left/2.png" className='tw-mr-[32px]' alt="partner-2" />
                                <img height="auto" width="auto" src="/partners/right/2.png" alt="partner-2" />
                            </div>
                            <div className="tw-flex tw-justify-center md:tw-justify-end tw-mb-[32px]">
                                <img height="auto" width="auto" src="/partners/left/3.png" alt="partner-3" className='tw-mr-[32px]' />
                                <img height="auto" width="auto" src="/partners/right/3.png" alt="partner-3" />

                            </div>
                            <div className="tw-flex tw-justify-center md:tw-justify-end">
                                <img height="auto" width="auto" src="/partners/right/4.png" alt="partner-4" />
                            </div>
                        </div>
                        <div className='md:tw-col-span-2 text-center'>
                            <h4 className='tw-uppercase tw-mb-[16px] mir-p-overline-lg mir-text-primary-500' style={{ marginTop: "59px" }}>our partners</h4>
                            <h2 className='mir-h1-semibold mir-text-neutral-900 tw-mb-[24px]'>Building bridges, <br /> creating opportunities</h2>
                            <p className='tw-max-w-full md:tw-max-w-[513px] tw-mb-[36px] mir-p-rg-regular mir-text-neutral-600 mx-auto'>At MIR Digital Solutions, we understand the significance of strong partnerships. Our collaborative approach fosters an environment where ideas flourish, and goals are surpassed.</p>
                            <button onClick={() => { setShowRegionPopup(true) }} className='mir-btn-lg mir-btn-lg-primary'>Connect with us</button>
                        </div>
                        <div className='md:tw-col-span-1 tw-my-[32px]'>
                            <div className="tw-flex tw-justify-center md:tw-justify-start tw-mb-[32px]">
                                <img height="auto" width="auto" src="/partners/right/1.png" alt="partner-1" className='tw-mr-[32px]' />
                                <div className='partner-image-border'>
                                    <img height="auto" className='partner-image-media' width="auto" src="/partners/right/7.png" alt="partner-1" />
                                </div>
                            </div>
                            <div className="tw-flex tw-justify-center md:tw-justify-start tw-mb-[32px]">
                                <img height="auto" width="auto" src="/partners/left/6.png" className="partner-image tw-mr-[32px]" alt="partner-6" />
                                <img height="auto" width="auto" src="/partners/right/6.png" className="partner-image" alt="partner-6" />
                            </div>
                            <div className="tw-flex tw-justify-center md:tw-justify-start tw-mb-[32px]">
                                <img height="auto" width="auto" src="/partners/left/5.png" className="partner-image tw-mr-[32px]" alt="partner-5" />
                                <img height="auto" width="auto" src="/partners/left/4.png" alt="partner-4" />
                            </div>
                            <div className="tw-flex tw-justify-center md:tw-justify-start">
                                <div className='partner-image-border tw-mr-[32px]'>
                                    <img height="auto" className='partner-image-media' width="auto" src="/partners/right/8.png" alt="partner-1" />
                                </div>
                                <div className='partner-image-border'>
                                    <img style={{width: "100%"}} height="auto" className='partner-image-media' width="auto" src="/partners/right/9.svg" alt="partner-1" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='tw-pt-[120px] tw-pb-[96px] tw-bg-[#030712]'>
                <div className="container mx-auto">
                    <div className="text-center">
                        <h4 className='mir-text-primary-500 tw-mb-[16px] mir-p-overline-lg tw-uppercase'>How are we different</h4>
                        <h2 className='text-white mir-display-semibold-sm tw-mb-[16px]'>Our Specialties</h2>
                        <p className='tw-max-w-full md:tw-max-w-[384px] mir-text-neutral-400 mx-auto mir-p-lg-regular tw-mb-[64px]'>Elevate your experience with innovative solutions tailored for success</p>
                    </div>
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-[32px] tw-mb-[64px]">
                        {
                            [
                                { number: '01/', title: 'End-to-end Transformation Partner', desc: 'Enterprise Product Development, Automation, Cloud/Virtualization Services' },
                                { number: '02/', title: 'Robust Implementation Methodology', desc: 'Structured approach to ensure on-time and in-budget delivery, customization and flexibility' },
                                { number: '03/', title: 'Seasoned Team', desc: 'Microsoft Implementation and Licensing Experts, hands-on team that is in it for the long run' },
                                { number: '04/', title: 'Product-minded View', desc: 'Solve your business problems with long-term growth in mind' },
                                { number: '05/', title: 'Drive Operational Efficiency', desc: 'Proven model for cost savings' },
                                { number: '06/', title: 'Change Champion', desc: 'Drive a change in process / culture / people' },
                            ].map((item, index) => {
                                return <div key={`speciality-item-${index}`} className='tw-relative tw-min-h-[298px] tw-bg-[#0F131E] tw-px-[48px] tw-py-[48px] tw-rounded-[12px] tw-flex tw-flex-col tw-justify-between' style={{ border: '2px solid rgba(255, 255, 255, 0.03)' }}>
                                    <img className="tw-absolute tw-z-0 tw-right-0 tw-top-0" src="/new-home/box-dark-shade.png" alt="shade-bg-image" />
                                    <div className="tw-z-10 tw-relative">
                                        <div className='mir-text-primary-500 mir-p-overline-lg tw-mb-[32px]'>{item.number}</div>
                                        <h4 className='tw-mb-[12px] text-white mir-h6-semibold tw-max-w-full md:tw-max-w-[320px]'>{item.title}</h4>
                                        <p className='mir-text-neutral-400 mir-p-rg-regular tw-m-0'>{item.desc}</p>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    <div className="text-center">
                        <button className='mir-btn-lg mir-btn-lg-primary mx-auto' onClick={() => { setShowRegionPopup(true) }}>Explore more about us</button>
                    </div>
                </div>
            </section>

            <Suspense>
                <HomeCarousel />
            </Suspense>

            <section className='sec-10' style={{ backgroundImage: 'url(/new-home/numbers-image.png)', backgroundSize: '703px 548px' }}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12'>
                            <h4 className='sec-10-sub-heading text-primary-500'>numbers talk</h4>
                            <h2 className='sec-10-heading mir-heading-l7-l2 text-secondary-700'>
                                Can you believe these numbers?
                            </h2>
                        </div>
                        <div className='col-lg-5 offset-lg-3'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className='sec-10-number-card' style={{ borderBottom: "1px solid #D6DBE0" }}>
                                        <span className='sec-10-number'>
                                            <CountUp end={94} duration={4} />%
                                        </span>
                                        <br className='sm-visible'></br>
                                        <span className='sec-10-number-text mir-heading-l3-l2'>of businesses</span>
                                        <div className='sec-10-num-desc'>
                                            reported security benefits they had been unable to achieve with their previous on-premises resources.
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-12'>
                                    <div className='sec-10-number-card' style={{ borderBottom: "1px solid #D6DBE0", paddingTop: "48px" }}>
                                        <span className='sec-10-number'>
                                            <CountUp end={80} duration={4} />%
                                        </span>
                                        <br className='sm-visible'></br>
                                        <span className='sec-10-number-text mir-heading-l3-l2'>of CEOs</span>
                                        <div className='sec-10-num-desc'>
                                            are investing more in digital technology to combat economic pressures like inflation, talent scarcity and supply constraint
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-12'>
                                    <div className='sec-10-number-card' style={{ paddingTop: "48px" }}>
                                        <span className='sec-10-number'>
                                            <CountUp end={25} duration={4} />%
                                        </span>
                                        <br className='sm-visible'></br>
                                        <span className='sec-10-number-text mir-heading-l3-l2'>SMBs with cloud investment</span>
                                        <div className='sec-10-num-desc'>
                                            reported a quarter of revenue growth and 2X the profits over those who don't
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='tw-py-[120px]'>
                <div className="container mx-auto">
                    <div className='tw-mb-[72px] d-flex justify-content-between flex-wrap align-items-end'>
                        <div>
                            <h4 className='tw-uppercase mir-text-primary-500 mir-p-overline-lg tw-mb-[16px]'>Case Studies</h4>
                            <h2 className='tw-mb-0 tw-max-w-full md:tw-max-w-[566px] mir-text-neutral-900 mir-h1-semibold tw'>Unlocking business potential with real-world results </h2>
                        </div>
                        <Link to={MIRRoutes.CaseStudies} className='tw-mt-4 md:tw-mt-0 mir-btn-lg mir-btn-lg-tertiary'>View All</Link>
                    </div>
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-[32px]">
                        {
                            [
                                {
                                    id: 8,
                                    metaSlug: 'law-firm-azure-interview-assessment-tool',
                                    metaTitle: 'Top Law Firm Cuts Down 300+ Hours of Manual Work with Microsoft Azure-based RecruIt Application Solution ',
                                    metaDesc: "Discover how MIR's automation solutions expedited interview scheduling and assessment for a top-ranked law firm in Canada.",
                                    date: "01 August 2023",
                                    title: "MIR Cuts 300+ Hours of Manual Work for Top Law Firm with Microsoft Azure-based RecruIt Application",
                                    image: '/case-studies/images/8.webp',
                                    downloadLink: '/case-studies/docs/8.pdf',
                                    body: <>
                                        Want to expedite your interview scheduling, assessment and co-ordination process? Look no further.  <br /><br />
                                        Our esteemed client, a leading law firm in Canada ranked among the top 5, encountered challenges in efficiently recruiting new university graduates. This was primarily attributed to reliance on external tools and outdated software, necessitating exhaustive efforts from supervisors during the interview events. <br /><br />
                                        As a trusted partner, MIR helped implement end-to-end process automation, unlocking the full potential for scheduling, interviewing, and assessing candidates  <br /> <br />
                                        <b>Download the case study now to learn more about this transformative project</b><br /><br />
                                        Start streamlining your candidate-to-hire process today!
                                    </>
                                },
                                {
                                    id: 1,
                                    date: '22 jul 2023',
                                    title: 'Construction and mining giant drives operational efficiency with Dynamics 365 Inventory and Warehouse Management',
                                    metaSlug: 'construction-mining-inventory-warehouse-management',
                                    metaTitle: 'Construction & Mining Giant Drives  Efficiency with Dynamics 365 Inventory & Warehouse Management',
                                    metaDesc: 'Discover how a Dynamics 365 solution empowered a construction & mining leader to conquer inventory management challenges and streamline processes.',
                                    image: '/case-studies/images/1.webp',
                                    downloadLink: '/case-studies/docs/1.pdf',
                                    body: <>
                                        <p>Download our case study to discover how our solution helped this construction and mining leader overcome their inventory management challenges, achieve transparent visibility, and streamline their processes.</p><br />
                                        <b>Fill up the form now and gain valuable insights into our successful implementation.</b> <br /><br />
                                        <p>Start optimizing your inventory processes today!</p>
                                    </>
                                },
                                {
                                    id: 2,
                                    date: '29 mar 2023',
                                    title: 'A&A Pharmachem Inc drives operational efficiencies with D365 Business Central and Power BI',
                                    metaSlug: 'aanda-pharmachem-business-central-power-bi',
                                    metaTitle: 'A&A Pharmachem Inc Drives Operational Efficiencies with Dynamics 365 Business Central & Power BI',
                                    metaDesc: 'Discover how a nutraceutical company achieved improved inventory management, financial reporting, & sales visibility by migrating their ERP system to the cloud.',
                                    image: '/case-studies/images/2.webp',
                                    downloadLink: '/case-studies/docs/2.pdf',
                                    body: <>
                                        Looking to streamline your business functions, increase productivity, and respond to financial and supply chain issues faster? <br /><br />
                                        Our client, a reputable nutraceutical and sports nutrition company, faced similar challenges and overcame them by migrating their legacy ERP system to the cloud with Microsoft Business Central. With our help as their trusted support partner, we implemented key Business Central functionalities and processes, customized PowerBI dashboards, and migrated legacy data. <br /><br />
                                        The result? Improved inventory management, financial reporting, and sales visibility.<br /><br />
                                        <b>Fill up our webform and download our case study to learn more about their success story and how you can achieve similar benefits for your own business.</b>
                                    </>
                                },
                            ].map((item, index) => {
                                return <div key={`case-study-content-${index}`} className='tw-w-full tw-group tw-cursor-pointer' onClick={() => { navigate(`/about-us/case-studies/${item.metaSlug}`) }}>
                                    <div className='tw-relative tw-mb-[32px] tw-w-full tw-h-[250px] tw-overflow-hidden tw-rounded-[8px]'>
                                        <img height="auto" width="auto" src={item.image} className='tw-w-full tw-object-cover tw-h-full tw-transition tw-transform group-hover:tw-scale-105' alt={item.title} />
                                    </div>
                                    <h4 className='tw-uppercase mir-p-overline-lg mir-text-neutral-400 tw-mb-[16px]'>{item.date}</h4>
                                    <h3 className='mir-text-neutral-800 group-hover:tw-text-[#004BD3] mir-h6-bold tw-pr-4'>{item.title}</h3>
                                </div>
                            })
                        }
                    </div>
                </div>
            </section>

            {/* <section className='tw-py-[120px] tw-relative' style={{ background: `url('/galaxy/bg.png')`}}>
                <img className='tw-absolute tw-w-[100px] md:tw-w-fit tw-right-0 tw-top-10' src="/new-home/planet-right.png" alt="planet-icon-right" />
                <img className='tw-absolute tw-w-[60px] md:tw-w-fit tw-left-10 md:tw-left-20 tw-bottom-0' src="/new-home/planet-left.png" alt="planet-icon-left" />
                <div className="container mx-auto text-center">
                    <h3 className='mir-text-neutral-100 mir-display-semibold-lg tw-mb-[16px]'>Let the work begin!</h3>
                    <p className='mir-text-neutral-200 mir-p-md-regular tw-mb-[32px]'>Start your journey to streamlined operations.</p>
                    <button onClick={() => { setShowRegionPopup(true) }} className='mir-btn-lg mir-btn-lg-primary'>Contact us now</button>
                </div>
            </section> */}

            <section className='sec-12 tw-bg-cover md:tw-bg-[100%] tw-bg-center'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12'>
                            <h4 className='sec-12-sub-heading'>
                                contact us
                            </h4>
                            <h2 className='sec-12-heading mir-heading-l8-l2 text-shades-100'>
                                Let the work <br /> begin!
                            </h2>
                            <h3 className='sec-12-desc text-shades-100'>
                                Start Your Journey to Streamlined Operations. Complete The Form To Get Started.
                            </h3>
                        </div>
                        <div className='col-lg-6 col-md-6 offset-lg-2 offset-md-2 offset-sm-2 offset-xs-2'>
                            <div className='sec-11-contact-card'>
                                <Suspense>
                                    <ContactForm />
                                </Suspense>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Suspense>
                <Footer />
            </Suspense>
        </main>
    )
}
