import React, { useEffect, useRef, useState } from 'react'
import Slider from "react-slick";
import './StepProcess.scss'

export default function StepsSlider({ subtitle, isLarge }) {

    const sliderRef = useRef(null);
    const sliderItemRefs = useRef(null);
    const [currentSlideWidth, setCurrentSlideWidth] = useState(400);
    const [slidesToShow, setSlidesToShow] = useState(4);

    useEffect(() => {
        let slides = document.querySelectorAll('.slick-slide.slick-current');
        if(slides){
            let slide = slides[0];
            let width = slide.offsetWidth;
            setCurrentSlideWidth(width + 70)
        }
        let width = window.screen.width;
        if(width > 1080) setSlidesToShow(4)
        if(width < 1080 && width >= 768) setSlidesToShow(3)
        if(width < 768) setSlidesToShow(1);
    }, [])

    var settings = {
        infinite: false,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        arrows: false,
        focusOnSelect: true,
        // // centerMode: false,
        // // centerPadding: '20%',
        // slidesToShow: 3,
        // infinite: false,
        // speed: 800,
        // // arrows: true,
        
    };

    const items = [
        { id: 1, name: 'Business validation' }, 
        { id: 2, name: 'Assessment' }, 
        { id: 3, name: 'Project plans' }, 
        { id: 4, name: 'Data migration' }, 
        { id: 5, name: 'System configuration' }, 
        { id: 6, name: 'Change management' }, 
        { id: 7, name: 'End-user training' }, 
        { id: 8, name: 'Post-implementation support' }, 
    ]

    return (
        <>
            <div className="process-slider-wrapper" style={{ overflowX: 'hidden' }}>
                <div className="container">
                    <div className='d-flex align-items-end justify-content-between w-100'>
                        <div className='flex-grow-1 text-left'>
                            <h3 className='mir-h5-semibold'>Our step-by-step process</h3>
                            { subtitle && !isLarge && <p className='mir-p-md-regular mt-3' style={{ maxWidth: '550px' }}>{subtitle}</p> }
                            { subtitle && isLarge && <p className='mir-p-md-regular mt-3' style={{ maxWidth: '75%' }}>{subtitle}</p> }
                        </div>
                        <div className="d-none d-md-block nav-btns flex-grow-0">
                            <div className='btn-wrapper'>
                                <button className='arrow-btn arrow-btn-prev' aria-label="previous" onClick={() => { sliderRef.current.slickPrev() }}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.21938 11.4694L9.96938 4.71945C10.1101 4.57872 10.301 4.49965 10.5 4.49965C10.699 4.49965 10.8899 4.57872 11.0306 4.71945C11.1714 4.86018 11.2504 5.05105 11.2504 5.25007C11.2504 5.44909 11.1714 5.63997 11.0306 5.7807L5.56031 11.2501L20.25 11.2501C20.4489 11.2501 20.6397 11.3291 20.7803 11.4697C20.921 11.6104 21 11.8012 21 12.0001C21 12.199 20.921 12.3897 20.7803 12.5304C20.6397 12.6711 20.4489 12.7501 20.25 12.7501L5.56031 12.7501L11.0306 18.2194C11.1714 18.3602 11.2504 18.551 11.2504 18.7501C11.2504 18.9491 11.1714 19.14 11.0306 19.2807C10.8899 19.4214 10.699 19.5005 10.5 19.5005C10.301 19.5005 10.1101 19.4214 9.96937 19.2807L3.21938 12.5307C3.14964 12.461 3.09432 12.3783 3.05658 12.2873C3.01884 12.1962 2.99941 12.0986 2.99941 12.0001C2.99941 11.9015 3.01884 11.8039 3.05658 11.7129C3.09432 11.6218 3.14964 11.5391 3.21938 11.4694Z" fill="#9CA3AF"/>
                                    </svg>
                                </button>
                                <button className='arrow-btn arrow-btn-next'  aria-label="next"onClick={() => { sliderRef.current.slickNext() }}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20.7806 12.5306L14.0306 19.2806C13.8899 19.4213 13.699 19.5003 13.5 19.5003C13.301 19.5003 13.1101 19.4213 12.9694 19.2806C12.8286 19.1398 12.7496 18.949 12.7496 18.7499C12.7496 18.5509 12.8286 18.36 12.9694 18.2193L18.4397 12.7499H3.75C3.55109 12.7499 3.36032 12.6709 3.21967 12.5303C3.07902 12.3896 3 12.1988 3 11.9999C3 11.801 3.07902 11.6103 3.21967 11.4696C3.36032 11.3289 3.55109 11.2499 3.75 11.2499H18.4397L12.9694 5.78055C12.8286 5.63982 12.7496 5.44895 12.7496 5.24993C12.7496 5.05091 12.8286 4.86003 12.9694 4.7193C13.1101 4.57857 13.301 4.49951 13.5 4.49951C13.699 4.49951 13.8899 4.57857 14.0306 4.7193L20.7806 11.4693C20.8504 11.539 20.9057 11.6217 20.9434 11.7127C20.9812 11.8038 21.0006 11.9014 21.0006 11.9999C21.0006 12.0985 20.9812 12.1961 20.9434 12.2871C20.9057 12.3782 20.8504 12.4609 20.7806 12.5306Z" fill="white"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='slider-wrapper mt-4'>
                        <Slider draggable={false}
                        {...settings} ref={sliderRef}>
                            {
                                items.map((item, i) => {
                                    return <div ref={sliderItemRefs} className='process-slider-item'>
                                        <div className='slide-item'>
                                            <div>
                                                <p className='mir-p-overline-sm'>STEP {item.id}</p>
                                                <h4 className='mir-h6-semibold'>{item.name}</h4>
                                            </div>
                                            <img src={`/supply-chain/process/${item.id}-gray.svg`} className='gray-icon' alt="slider-item-icon-image" />
                                            <img src={`/supply-chain/process/${item.id}.svg`} className='blue-icon' alt="slider-item-icon-image" />
                                        </div>
                                    </div>
                                })
                            }
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    )
}
