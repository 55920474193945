import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeComponent from "../components/HomeComponent/HomeComponent";
import SupplyChainManagement from "../components/CloudSolutions/SupplyChainManagement";
import ProjectOperations from "../components/CloudSolutions/ProjectOperations";
import Sales from "../components/CloudSolutions/Sales";
import BusinessCentralPage from "../components/CloudSolutions/BusinessCentral";
import BusinessIntelligence from "../components/CloudSolutions/business-intelligence";
import PowerApps from "../components/CloudSolutions/PowerApps";
import PowerBi from "../components/CloudSolutions/PowerBI";
import StaffingSolutions from "../components/CloudSolutions/staffing-solutions";
import ERP from "../components/BusinessSolutions/ERP";
import CRM from "../components/BusinessSolutions/CRM";
import CaseStudies from "../components/CaseStudies";
import CaseStudyDetails from "../components/CaseStudies/CaseStudy";
import ProjectRescue from "../components/CloudSolutions/project-rescue";
import BlogList from "../components/blogs/BlogList";
import ReadBlog from "../components/blogs/ReadBlog";
import ScrollToTop from "../lib/scrollToTop";
import CloudServices from "../components/BusinessSolutions/CloudServices";
import PrivacyPolicy from "../components/privacy-policy";
import ContactUs from "../components/ContactUs";
import MIRRoutes from "../lib/routes";
import Header from "../components/Header";
import InterviewAssessment from "../components/CloudSolutions/interview-assessment";
import BCBundles from "../components/Bundles/BCBundles";
import MIRTeam from "../components/MIRTeam";
import SharePoint from "../components/SharePoint";
import Microsoft365 from "../components/Microsoft365";
import LowCode from "../components/LowCode";
import CRMBundles from "../components/Bundles/CRMBundles";
import TimeIt from "../components/TimeIt";
import HomeComponentNew from "../components/HomeComponentNew";
import BusinessScience from "../components/CloudSolutions/data-science";
import MIRCulture from "../components/MIRCulture";
import Partners from "../components/Partners/Partners";
import Careers from "../components/Careers";
import CareersJobs from "../components/Careers/openings";
import MicrosoftPurview from "../components/MicrosoftPurview";

function AppRoutes() {
    return (
        <BrowserRouter>
            <Header></Header>
            <ScrollToTop />
            <Routes>
                {/* <Route exact path={MIRRoutes.Home} element={<HomeComponent />} /> */}
                <Route exact path={MIRRoutes.Home} element={<HomeComponentNew />} />
                <Route exact path={MIRRoutes.SupplyChain} element={<SupplyChainManagement />} />
                <Route exact path={MIRRoutes.ProjectOperations} element={<ProjectOperations />} />
                <Route exact path={MIRRoutes.Sales} element={<Sales />} />
                <Route exact path={MIRRoutes.BusinessCentral} element={<BusinessCentralPage />} />
                <Route exact path={MIRRoutes.BusinessIntelligence} element={<BusinessIntelligence />} />
                <Route exact path={MIRRoutes.BusinessScience} element={<BusinessScience />} />
                <Route exact path={MIRRoutes.PowerApps} element={<PowerApps />} />
                <Route exact path={MIRRoutes.PowerBI} element={<PowerBi />} />
                <Route exact path={MIRRoutes.StaffingSolutions} element={<StaffingSolutions />} />
                <Route exact path={MIRRoutes.ProjectRescue} element={<ProjectRescue />} />
                <Route exact path={MIRRoutes.BCBundles} element={<BCBundles />} />
                <Route exact path={MIRRoutes.CRMBundles} element={<CRMBundles />} />
                <Route exact path={MIRRoutes.SharePoint} element={<SharePoint />} />
                <Route exact path={MIRRoutes.Microsoft365} element={<Microsoft365 />} />
                <Route exact path={MIRRoutes.MicrosoftPurview} element={<MicrosoftPurview />} />
                <Route exact path={MIRRoutes.LowCode} element={<LowCode />} />
                <Route exact path={MIRRoutes.TimeIt} element={<TimeIt />} />

                {/* mir team */}
                <Route exact path={MIRRoutes.MIRTeam} element={<MIRTeam />} />

                {/* mir partners */}
                <Route exact path={MIRRoutes.Partners} element={<Partners />} />

                {/* mir careers */}
                <Route exact path={MIRRoutes.Careers} element={<Careers />} />
                <Route exact path={MIRRoutes.CareersOpenings} element={<CareersJobs />} />

                {/* case studies */}
                <Route exact path={MIRRoutes.CaseStudies} element={<CaseStudies />} />
                <Route path="/about-us/case-studies/:slug" element={<CaseStudyDetails />} />

                {/*mir family routes*/}
                <Route exact path={MIRRoutes.MIRCulture} element={<MIRCulture />} />

                {/* business solutions */}
                <Route exact path={MIRRoutes.ERP} element={<ERP />} />
                <Route exact path={MIRRoutes.CRM} element={<CRM />} />
                <Route exact path={MIRRoutes.CloudServices} element={<CloudServices />} />

                <Route exact path={MIRRoutes.Contact} element={<ContactUs />} />
                <Route exact path={MIRRoutes.InterviewAssesment} element={<InterviewAssessment />} />

                <Route exact path={MIRRoutes.Contact} element={<ContactUs />} />


                {/* Blogs */}
                <Route exact path="/blogs" element={<BlogList />} />
                <Route exact path="/blogs/read" element={<ReadBlog />} />
                <Route exact path="/blogs/:random/:name" element={<ReadBlog />} />

                {/* Privacy Policy */}
                <Route exact path={MIRRoutes.PrivacyPolicy} element={<PrivacyPolicy />} />
            </Routes>
        </BrowserRouter>

    );
}

export default AppRoutes;