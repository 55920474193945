const PartnersContent = [
    {
        id: 1,
        partnerName: 'Microsoft',
        partnerLink: "https://www.microsoft.com/en-us",
        partnerImage: '/partner-page-logos/1.png',
        partnerDetails: <>
            A global leader in technology, Microsoft empowers businesses with cutting-edge software, cloud computing, and AI-driven solutions like Microsoft Azure, Dynamics 365, and Microsoft 365.
        </>
    },
    {
        id: 14,
        partnerName: 'VMware',
        partnerLink: " https://www.vmware.com/",
        partnerImage: '/partner-page-logos/14.svg',
        partnerDetails: <>
            Renowned for its virtualization and cloud infrastructure solutions, VMware enables organizations to modernize their IT environments with scalable and secure technology.
        </>
    },
    {
        id: 2,
        partnerName: 'TD (Toronto-Dominion Bank)',
        partnerLink: "https://www.td.com/ca/en/about-td",
        partnerImage: '/partner-page-logos/2.png',
        partnerDetails: <>
            A leading North American financial institution, TD provides innovative banking, investment, and financial services tailored to empower businesses and individuals.
        </>
    },
    {
        id: 3,
        partnerName: 'A10 Networks',
        partnerLink: "https://www.a10networks.com/customers/large-canadian-government-agency/",
        partnerImage: '/partner-page-logos/3.png',
        partnerDetails: <>
            Specializing in application delivery and network security, A10 offers advanced solutions for enhancing the availability, reliability, and security of business-critical applications.
        </>
    },
    {
        id: 4,
        partnerName: 'Helux',
        partnerLink: "https://helux.ai/technologies",
        partnerImage: '/partner-page-logos/4.png',
        partnerDetails: <>
            A pioneer in information management, Helux specializes in SharePoint and Microsoft 365, helping organizations optimize digital collaboration and content nfrmanagement.
        </>
    },
    {
        id: 5,
        partnerName: 'ITS Global',
        partnerLink: "https://www.itsglobal.ca/s",
        partnerImage: '/partner-page-logos/5.png',
        partnerDetails: <>
            An IT services leader, ITS Global delivers robust cybersecurity, managed services, and IT infrastructure solutions for businesses seeking reliable digital transformation.
        </>
    },
    {
        id: 6,
        partnerName: 'Compugen',
        partnerLink: "https://www.compugen.com/",
        partnerImage: '/partner-page-logos/6.png',
        partnerDetails: <>
            A Canadian IT solutions provider, Compugen enables businesses to leverage technology through managed services, infrastructure modernization, and workplace transformation.
        </>
    },
    {
        id: 7,
        partnerName: 'Pax8',
        partnerLink: "https://www.pax8.com/en-us/professional-services/?utm_source=google_adwords&utm_medium=ppc&utm_campaign=pax8_website&utm_term=brand&utm_content=unkown",
        partnerImage: '/partner-page-logos/7.png',
        partnerDetails: <>
            As a leading cloud distributor, Pax8 simplifies the cloud buying journey, offering a broad range of cloud solutions and services for modern businesses
        </>
    },
    {
        id: 8,
        partnerName: 'CIBC (Canadian Imperial Bank of Commerce)',
        partnerLink: "https://www.cibc.com/en/about-cibc.html",
        partnerImage: '/partner-page-logos/8.png',
        partnerDetails: <>
            A prominent Canadian bank, CIBC provides innovative banking solutions and expert financial advice tailored to the needs of businesses and individuals.
        </>
    },
    {
        id: 9,
        partnerName: 'Red Hat',
        partnerLink: "https://www.redhat.com/en",
        partnerImage: '/partner-page-logos/9.png',
        partnerDetails: <>
            A subsidiary of IBM, Red Hat is a leader in open-source solutions, offering enterprise-grade products and services like Red Hat Enterprise Linux and Kubernetes.
        </>
    },
    {
        id: 15,
        partnerName: 'Crayon',
        partnerLink: "https://www.crayon.com/",
        partnerImage: '/partner-page-logos/15.png',
        partnerDetails: <>
            A global leader in IT services and digital transformation, Crayon specializes in cloud optimization, software asset management, and cutting-edge IT solutions to drive innovation.
        </>
    },
    {
        id: 11,
        partnerName: 'Ingram Micro',
        partnerLink: "https://www.ingrammicro.com/",
        partnerImage: '/partner-page-logos/11.png',
        partnerDetails: <>
            A global leader in technology distribution, Ingram Micro supports businesses with IT supply chain services, cloud solutions, and value-added technology offerings.
        </>
    },
    {
        id: 12,
        partnerName: 'ePlus Technology',
        partnerLink: "https://www.eplus.com/",
        partnerImage: '/partner-page-logos/12.png',
        partnerDetails: <>
            Delivering cutting-edge IT solutions, ePlus Technology empowers businesses with services in cloud, security, data center, and digital infrastructure transformation.
        </>
    },
    {
        id: 13,
        partnerName: 'Procern',
        partnerLink: "https://procern.com/",
        partnerImage: '/partner-page-logos/13.svg',
        partnerDetails: <>
            An innovative IT and digital services provider, Procern specializes in cybersecurity, cloud computing, and enterprise IT solutions to enhance business efficiency and resilience.
        </>
    }
]

export default PartnersContent;