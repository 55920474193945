import { Helmet } from "react-helmet";
import Footer from "../Footer/Footer";
import StickyBanner from "../StickyBanner/StickyBanner";
import BookNowModal from "../CommonElements/BookNowModal";
import { useState } from "react";

export default function CareersJobs() {
    const [showRegionPopup, setShowRegionPopup] = useState(false);
    return (
        <main>
            <Helmet>
                <meta charSet="utf-8" />
                <title>MIR Careers job openings | MIR Digital Solutions</title>
                <meta name="description" content="we connect exceptional IT professionals with transformative opportunities across industries" />
                <link rel="canonical" href={`${window.location.origin}/about-us/case-studies`} />
            </Helmet>
            <StickyBanner />
            <BookNowModal showRegionPopup={showRegionPopup} setShowRegionPopup={setShowRegionPopup} />
            <section className='tw-pt-[60px] tw-pb-[60px] bg-no-repeat' style={{ backgroundImage: 'url(/case-studies/hero-bg.png)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="container mx-auto">
                    <div className='text-center mx-auto tw-max-w-full md:tw-max-w-[900px]'>
                        <h1 className='mir-display-semibold-sm mir-text-neutral-100 tw-mb-[16px]'>Welcome to MIR Digital Career Portal</h1>
                        <h4 className='mir-text-neutral-400 mir-p-md-regular'>
                        Are you ready to make an impact? Join MIR Digital and take your career to the next level by contributing to innovative projects that transform industries.
                        </h4>
                    </div>
                </div>
            </section>
            <div className="container">
                <div className='mt-5' id="example-widget-container"></div>
            </div>
            <Footer />
        </main>
    );
}