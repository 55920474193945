import React, { useState } from 'react'
import StickyBanner from '../../StickyBanner/StickyBanner'
import Footer from '../../Footer/Footer'
import SectionTabs from '../../CloudSolutions/Elements/Sections/SectionTabs/SectionTabs';
import BookNowModal from '../../CommonElements/BookNowModal';
import HowHelpSection from '../../CloudSolutions/Elements/Sections/HowHelpSection';
import SuccessStory from '../../CloudSolutions/Elements/Sections/SuccessStory';
import ContactSection from '../../CloudSolutions/Elements/Sections/ContactSection';
import { Helmet } from 'react-helmet';
import CaseStudyUrls from '../../../lib/singleCaseStudyUrls';
import SuccessStoriesDynamic from '../../CommonElements/SuccessStoriesDynamic';

export default function CloudServices() {
    const [showVideo, setShowVideo] = useState(false);
    const [tabs, setTabs] = useState([
        { name: 'Overview', id: 'section-1' },
        { name: 'Introduction', id: 'section-2' },
        { name: 'Benefits', id: 'section-3' },
        { name: 'How we can help', id: 'section-4' },
        { name: 'Case studies', id: 'section-5' },
        { name: 'Contact Us', id: 'section-6' },
    ]);

    const successStoriesContent = [
        {
            image: "/success-stories-custom/6.png",
            timeToRead: "10",
            desc: "6Harmonics Drives Agility at Workplace with Cloud and Office 365 Migration",
            linkToRead: "/about-us/case-studies/6-harmonics-agility-microsoft-365"
        },
        {
            image: "/success-stories-custom/3.png",
            timeToRead: "3",
            desc: "Make A Wish Upscales Organization Operations & Reduce Ongoing Costs with Azure Public Migration",
            linkToRead: "/about-us/case-studies/make-a-wish-canada-azure-migration"
        },
        {
            image: "/success-stories-custom/12.png",
            timeToRead: "5",
            desc: "Boosting Internal Efficiency: Construction and Mining Leader Integrates Self-Serve Portal With Microsoft Dynamics 365",
            linkToRead: "/about-us/case-studies/self-serve-portal"
        }
    ];

    const [showRegionPopup, setShowRegionPopup] = useState(false);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Efficient Cloud Migration & Computing| MIR Digital Solutions</title>
                <meta name="description" content="Experience maximum ROI, agility, & security with our seasoned experts in cloud migration, leveraging best practices & automation for successful implementations." />
                <link rel="canonical" href={`${window.location.origin}/business-solutions/cloud-services`} />
            </Helmet>
            <main>

                <StickyBanner />
                <BookNowModal showRegionPopup={showRegionPopup} setShowRegionPopup={setShowRegionPopup} />

                <section className='tw-pt-[120px] tw-pb-[160px] tw-bg-cover tw-bg-center tw-bg-no-repeat' style={{ backgroundImage: 'url(/supply-chain/bg.svg)' }}>
                    <div className="container mx-auto">
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-[137px]">
                            <div>
                                <h1 className='tw-mb-[24px] mir-display-semibold-sm mir-text-neutral-800 tw-max-w-full md:tw-max-w-[529px]'>Cloud Services</h1>
                                <h3 className='mir-text-neutral-600 tw-pb-[36px] mir-p-lg-regular m-0 tw-max-w-full md:tw-max-w-[486px]'>
                                    Your cloud, our expertise: Migrate, optimize, modernize with Microsoft Azure or AWS.
                                </h3>
                                <button onClick={() => { setShowRegionPopup(true) }} className='mir-btn-lg mir-btn-primary mir-p-rg-medium'>
                                    Contact us today
                                </button>
                            </div>
                            <div className='text-center'>
                                <img className='azure-aws-image' src='/cloud-services/azure.png'/>
                                <img className='azure-aws-image' src='/cloud-services/aws.jpg'/>
                            </div>
                        </div>

                    </div>
                </section>

                {/* section tabs */}
                <SectionTabs tabs={tabs} />

                <section className='tw-pt-[120px] tw-pb-[96px]' data-section id='section-1'>
                    <div className="container mx-auto text-left">
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-[137px]">
                            <div>
                                <h4 className='mir-p-overline-lg mir-text-primary-500 tw-mb-[16px]'>OVERVIEW</h4>
                                <h2 className='tw-max-w-full md:tw-max-w-[488px] mir-h1-semibold mir-text-neutral-800 tw-mb-[32px]'>Unlock growth and efficiency with Cloud computing</h2>
                                <p className='mir-p-md-regular tw-max-w-full md:tw-max-w-[534px] mir-text-neutral-800'>
                                    Prior to cloud computing, businesses relied on in-house data centers and on-premises infrastructure to host their applications and manage data. This approach had its challenges:
                                </p>
                            </div>
                            <div className='tw-flex tw-flex-col tw-items-center tw-justify-center'>
                                {
                                    [
                                        { heading: 'Scalability', desc: 'Expanding or downsizing on-premises infrastructure could be time-consuming and expensive.' },
                                        { heading: 'Costs', desc: 'Maintaining physical hardware, cooling systems, and power requirements incurred high capital and operational costs.' },
                                        { heading: 'Complexity', desc: 'Managing and upgrading hardware and software, as well as ensuring security and compliance, presented ongoing challenges.' },
                                    ].map((item, index) => {
                                        return <div className='tw-flex tw-items-start tw-justify-start tw-mb-[32px]'>
                                            <img className='tw-mr-[24px]' src="/cloud-services/overview-sadness.svg" alt="cloud-services-challenges" />
                                            <div>
                                                <h5 className='mir-h6-semibold mir-text-neutral-900 tw-mb-[8px]'>{item.heading}</h5>
                                                <p className='mir-p-rg-regular'>{item.desc}</p>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </section>

                <section className='mir-bg-neutral-100 tw-relative tw-pb-[64px]'>
                    <div className="tw-absolute tw-bg-white tw-w-full tw-h-[150px] tw-top-0 tw-left-0 tw-right-0 tw-z-[0]"></div>
                    <div className="tw-max-w-[80%] md:tw-max-w-[864px] mx-auto tw-relative tw-z-10">
                        <div className='tw-p-[24px] md:tw-p-[64px] text-center tw-bg-white tw-rounded-[12px]' style={{ boxShadow: '0px 8px 12px 0px rgba(0, 19, 58, 0.06), 0px 0px 23px 0px rgba(0, 19, 58, 0.04)' }}>
                            <img src="/cloud-services/question-icon.svg" alt="question-icon" className='tw-mb-[16px]' />
                            <h4 className='mir-p-overline-lg tw-uppercase tw-mb-[36px] mir-text-neutral-500'>Question to ask your team</h4>
                            <h2 className='mir-text-neutral-800 mir-h4-semibold tw-max-w-full md:tw-max-w-[768px] mx-auto'>Is your business harnessing the full potential of cloud computing to drive growth, innovation, and efficiency?</h2>
                        </div>
                    </div>
                </section>

                <section className='tw-pt-[64px] tw-pb-[72px] mir-bg-neutral-100' data-section id='section-2'>
                    <div className="container mx-auto text-center">
                        <h4 className='tw-uppercase mir-p-overline-lg tw-mb-[16px] mir-text-primary-500'>Introduction</h4>
                        <h2 className='mir-neutral-800 mir-h1-semibold tw-mb-[24px]'>What is Cloud Migration?</h2>
                        <p className='tw-max-w-full md:tw-max-w-[798px] mx-auto mir-p-md-regular mir-text-neutral-700 tw-mb-[36px]'>Cloud migration refers to the transfer of applications and data from on-premises servers to a public cloud provider's server or between different cloud platforms. This process offers numerous benefits such as cost reduction, enhanced performance, improved security, convenience, and other advantages.</p>
                        <div className='tw-mb-[80px]'>
                            <button onClick={() => { setShowRegionPopup(true) }} className='mir-btn-lg mir-btn-primary mir-p-rg-medium tw-mr-[18px]'>Book a meeting</button>
                            <button onClick={() => { window.open('/cloud-services/brochure.pdf', "_blank") }} className='mir-btn-lg mir-btn-transparent mir-text-neutral-800 mir-p-rg-medium tw-group'>
                                <span className='tw-mr-[8px]'>View Brochure</span>
                                <svg width="21" height="21" className='tw-fill-slate-900 group-hover:tw-fill-blue-600' viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.6922 11.3654L8.44218 17.6154C8.38412 17.6735 8.31518 17.7195 8.23931 17.7509C8.16344 17.7824 8.08212 17.7985 8 17.7985C7.91787 17.7985 7.83656 17.7824 7.76069 17.7509C7.68482 17.7195 7.61588 17.6735 7.55781 17.6154C7.49974 17.5573 7.45368 17.4884 7.42225 17.4125C7.39082 17.3366 7.37465 17.2553 7.37465 17.1732C7.37465 17.0911 7.39082 17.0098 7.42225 16.9339C7.45368 16.858 7.49974 16.7891 7.55781 16.731L13.3664 10.9232L7.55781 5.11539C7.44053 4.99811 7.37465 4.83905 7.37465 4.6732C7.37465 4.50735 7.44053 4.34829 7.55781 4.23101C7.67508 4.11374 7.83414 4.04785 8 4.04785C8.16585 4.04785 8.32491 4.11374 8.44218 4.23101L14.6922 10.481C14.7503 10.5391 14.7964 10.608 14.8278 10.6839C14.8593 10.7597 14.8755 10.8411 14.8755 10.9232C14.8755 11.0053 14.8593 11.0867 14.8278 11.1625C14.7964 11.2384 14.7503 11.3073 14.6922 11.3654Z" />
                                </svg>
                            </button>
                        </div>

                        <div className='tw-grid tw-cols-1 md:tw-grid-cols-3 tw-gap-x-[32px] tw-gap-y-[56px]'>
                            {
                                [
                                    { img: '/cloud-services/features/1.svg', heading: 'Flexibility and scalability', desc: 'Cloud platforms offer on-demand computing resources, allowing organizations to scale resources as needed. With global presence options, workloads can be run closer to customers for improved performance.' },
                                    { img: '/cloud-services/features/2.svg', heading: 'Enhanced security', desc: 'Public clouds provide built-in security at the platform level, along with specialized security tools. Automatic security patching is often done by the cloud provider, ensuring data and applications are protected.' },
                                    { img: '/cloud-services/features/3.svg', heading: 'Simplified management and monitoring', desc: 'Cloud providers with central management tools allow for easy management and monitoring of datacenter and cloud resources from a single interface.' },
                                    { img: '/cloud-services/features/4.svg', heading: 'Cost optimization', desc: 'By optimizing workloads for cost and eliminating equipment, maintenance, and real estate expenses, businesses can achieve significant cost savings.' },
                                    { img: '/cloud-services/features/5.svg', heading: 'Backup, recovery and failover', desc: 'Most cloud providers offer built-in backup and recovery capabilities, including the ability to store backups in different geographic regions for added resilience.' },
                                    { img: '/cloud-services/features/6.svg', heading: 'Compliance', desc: 'Specialized offerings from cloud platforms cater to highly regulated industries, simplifying compliance requirements.' },
                                ].map((item, index) => {
                                    return <div key={`intro-features-index-${index}`} className='text-start'>
                                        <img src={item.img} alt={item.heading} className='tw-mb-[24px]' />
                                        <h5 className='mir-h6-semibold mir-text-neutral-800 tw-mb-[12px]'>{item.heading}</h5>
                                        <p className='mir-text-neutral-600 mir-p-rg-regular tw-max-w-full md:tw-max-w-[384px]'>{item.desc}</p>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </section>

                <section className='tw-pt-[32px] tw-pb-[64px] mir-bg-neutral-100'>
                    <div className="container mx-auto">
                        <div className='tw-grid tw-cols-1 md:tw-grid-cols-2 tw-gap-[56px]'>
                            <div className='tw-w-full tw-h-full tw-flex tw-items-start tw-justify-center tw-flex-col'>
                                <h2 className='mir-h2-semibold mir-text-neutral-800 tw-mb-[24px]'>What is Cloud Optimization?</h2>
                                <p className='mir-text-neutral-700 mir-p-md-regular'>The process of maximizing efficiency and cost-effectiveness in a cloud environment by fine-tuning resources and configurations, resulting in improved performance and reduced costs. </p>
                            </div>
                            <div className='tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center'>
                                <img src="/cloud-services/cloud-optimization.png" className='tw-w-full' alt="What is Cloud Optimization?" />
                            </div>

                        </div>
                    </div>
                </section>

                <section className='tw-pt-[32px] tw-pb-[96px] mir-bg-neutral-100'>
                    <div className="container mx-auto">
                        <div className='tw-grid tw-cols-1 md:tw-grid-cols-2 tw-gap-[56px]'>
                            <div className='tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center'>
                                <img src="/cloud-services/app-modernizer.png" className='tw-w-full' alt="What is App Modernization?" />
                            </div>
                            <div className='tw-w-full tw-h-full tw-flex tw-items-start tw-justify-center tw-flex-col'>
                                <h2 className='mir-h2-semibold mir-text-neutral-800 tw-mb-[24px]'>What is App Modernization?</h2>
                                <p className='mir-text-neutral-700 mir-p-md-regular'>Involves updating existing applications and systems to modern versions and platforms to improve performance, user experience, and reduce technical debt, supporting digital transformation. </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='tw-pt-[96px] tw-pb-[64px]'>
                    <div className="container mx-auto">
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-[136px]">
                            <div>
                                <h4 className='tw-uppercase mir-p-overline-lg mir-text-primary-500 tw-mb-[16px]'>about Public Cloud</h4>
                                <h2 className='tw-mb-[24px] mir-h1-semibold mir-text-neutral-800'>What is Cloud Migration and Modernization?</h2>
                                <p className='mir-text-neutral-700 mir-p-md-regular'>Public cloud migration and modernization allow businesses to leverage the full potential of cloud computing and stay competitive in the market.</p>
                            </div>
                            <div>
                                {
                                    [
                                        'Cloud migration and modernization refer to the process of moving existing applications, workloads, and data to Public Cloud while optimizing them for the cloud platform. This enables organizations to enhance scalability, performance, security, and cost-effectiveness.',
                                        'Key aspects of cloud migration and modernization include transferring applications and workloads, modernizing applications to leverage cloud-native services, and optimizing data management.',
                                        "Organizations prioritize cost-efficiency, security, compliance, monitoring, and training for maximizing ROI.",
                                    ].map((item, index) => {
                                        return <div key={`check-item-${index}`} className='tw-flex tw-items-start tw-justify-start tw-mb-[32px]'>
                                            <img src="/cloud-services/check-icon.svg" className='tw-mr-[16px]' alt="check-icon" />
                                            <p className='mir-p-md-regular mir-text-neutral-700'>{item}</p>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </section>

                <section className='tw-pb-[64px] tw-relative tw-overflow-hidden'>
                    <div className="tw-relative tw-z-10">
                        <div className='tw-pb-0 text-center md:tw-pb-[147px]'>
                            {
                                !showVideo && <div className='tw-flex tw-relative tw-max-w-full md:tw-max-w-[1012px] tw-min-h-[220px] tw-max-h-fit md:tw-h-[540px] tw-mx-auto tw-items-center tw-justify-center'>
                                    <img src="/business/video-placeholder.png" className='tw-absolute tw-z-[-10] tw-inset-0 tw-w-full tw-object-cover tw-h-full' alt="business-central-video" />
                                    <div className='tw-flex tw-items-center tw-justify-start tw-absolute tw-left-10 tw-bottom-10'>
                                        <img src="/business/play-button.svg" className='tw-mr-2 tw-w-[24px] tw-h-[24px] md:tw-w-[52px] md:tw-h-[52px] tw-cursor-pointer tw-transition hover:tw-scale-90 tw-transform' alt="play-button-icon" onClick={() => { setShowVideo(true) }} />
                                        <div className='text-start'>
                                            <p className='mir-p-md-regular tw-m-0 tw-text-white'>Play this video</p>
                                            <p className='mir-p-sm-regular tw-m-0 tw-text-white'>To get to know more</p>
                                        </div>
                                    </div>
                                </div>
                            }
                            {showVideo && <iframe className='tw-max-w-full md:tw-max-w-[1012px] mx-auto tw-min-h-[220px] tw-max-h-fit md:tw-h-[540px]' width={'100%'} src="https://www.youtube.com/embed/IJMxCYnVbew?si=U1oFG6QAsm1iuzBk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>}
                        </div>
                    </div>
                </section>

                <section className='tw-pb-[120px] tw-pt-[64px] tw-relative' data-section id='section-3'>
                    <div className="container mx-auto">
                        <div className='text-center'>
                            <h4 className='tw-uppercase tw-mb-[16px] mir-text-primary-500 mir-p-overline-lg'>benefits</h4>
                            <h2 className='tw-mb-[64px] mir-text-neutral-800 mir-h2-semibold tw-max-w-full md:tw-max-w-[542px] mx-auto'>
                                Make the smart choice with Azure or AWS Cloud Migration
                            </h2>
                        </div>
                        <div className='tw-py-[32px] tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-[104px]'>
                            <div className='tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center'>
                                <img src="/cloud-services/benefits/1.png" className='tw-w-full' alt="Microsoft Azure migrate and data modernization instruction demo screen" />
                            </div>
                            <div className='tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center'>
                                <div>
                                    <h3 className='tw-mb-[16px] mir-h5-semibold mir-text-neutral-800'>Scalability Benefits</h3>
                                    <p className='mir-text-neutral-700 mir-p-md-regular'>
                                        Organizations that opt for Azure or AWS migration and modernization can leverage many benefits, including enhanced scalability, improved performance, increased security, cost optimization, innovation, and simplified management.
                                    </p>
                                </div>
                            </div>
                            <div className='tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center'>
                                <img src="/cloud-services/benefits/1.1.png" className='tw-w-full' alt="Microsoft Azure migrate and data modernization instruction demo screen" />
                            </div>
                        </div>
                        <div className='tw-py-[32px] tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-[104px]'>
                            <div className='tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center'>
                                <img src="/cloud-services/benefits/2.png" className='tw-w-full' alt="Azure migration guide on servers, databases and web apps to ensure security and compliance advantage" />
                            </div>
                            <div className='tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center'>
                                <div>
                                    <h3 className='tw-mb-[16px] mir-h5-semibold mir-text-neutral-800'>Security and Compliance Advantage</h3>
                                    <p className='mir-text-neutral-700 mir-p-md-regular'>
                                        By migrating applications to Public cloud, businesses can take advantage of robust security and compliance, as well as advanced features such as built-in threat detection and encryption. Furthermore, with a pay-as-you-go model, businesses can optimize costs and avoid over-provisioning by paying only for the resources they use.
                                    </p>
                                </div>
                            </div>
                            <div className='tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center'>
                                <img src="/cloud-services/benefits/2.1.png" className='tw-w-full' alt="Azure migration guide on servers, databases and web apps to ensure security and compliance advantage" />
                            </div>
                        </div>
                        <div className='tw-py-[32px] tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-[104px]'>
                            <div className='tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center'>
                                <img src="/cloud-services/benefits/3.png" className='tw-w-full' alt="Microsoft Azure cloud-native innovation dashboard, recommended deployment and discovered entities overview demo screen" />
                            </div>
                            <div className='tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center'>
                                <div>
                                    <h3 className='tw-mb-[16px] mir-h5-semibold mir-text-neutral-800'>Cloud-native Innovation</h3>
                                    <p className='mir-text-neutral-700 mir-p-md-regular'>
                                        By utilizing cloud-native services and technologies, like AI and analytics, organizations can drive innovation and agility. Azure and AWS also provide a comprehensive set of management tools for monitoring, automating, and optimizing infrastructure and applications, streamlining operations, and improving efficiency for businesses.
                                    </p>
                                </div>
                            </div>
                            <div className='tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center'>
                                <img src="/cloud-services/benefits/3.1.png" className='tw-w-full' alt="Microsoft Azure cloud-native innovation dashboard, recommended deployment and discovered entities overview demo screen" />
                            </div>
                        </div>
                    </div>
                </section>

                <section className='tw-py-[96px] mir-bg-primary-600 tw-relative'>
                    <img src="/supply-chain/light-blue-banner-bg.png" alt="light-banner-bg" className='tw-absolute tw-inset-0 tw-object-cover tw-h-full tw-w-full' />
                    <div className="container mx-auto tw-relative z-10">
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <h3 className='mir-text-neutral-100 mir-h4-semibold tw-max-w-full tw-mb-[36px] md:tw-mb-0'>Experience the future of development with Microsoft Power Platform's Low-code Solutions</h3>
                            </div>
                            <div className="col-12 col-md-6 tw-flex tw-justify-start md:tw-justify-end tw-items-center">
                                <button onClick={() => { setShowRegionPopup(true) }} className='mir-btn-lg mir-btn-secondary tw-mt-[32px] md:tw-mt-0'>
                                    <span className='tw-ml-2'>Begin your journey today</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='tw-py-[96px] mir-bg-neutral-100 tw-relative'>
                    <div className="container mx-auto">
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-[136px]">
                            <div>
                                <h2 className='tw-mb-[24px] mir-h1-semibold mir-text-neutral-800'>MIR Cloud Migration Offerings</h2>
                                <p className='mir-text-neutral-700 mir-p-md-regular'>Every business has unique infrastructure and solutions requirements. Our role is to migrate your legacy data, applications to the new cloud environments while adhering to a robust architecture, a reasonable budget, and an effective implementation strategy. We will work with you to continuously monitor the cloud resources while analyzing, with an aim to keeping the cloud costs closest to your budget. </p>
                            </div>
                            <div>
                                {
                                    [
                                        { heading: 'Re-hosting', desc: "Known as IaaS (Lift and Shift) with no code changes, this strategy involves moving applications from the current hosting environment to a public cloud infrastructure. " },
                                        { heading: 'Re-platforming', desc: "Known as PaaS Containerization with minimal code changes, it’s an application migration strategy that leverages cloud deployment model." },
                                        { heading: 'Re-factoring', desc: "Known as Re-architecting Cloud Native apps with complete rebuild, the strategy involves turning an application into a cloud-native application. " },
                                    ].map((item, index) => {
                                        return <div key={`check-item-${index}`} className='tw-flex tw-items-start tw-justify-start tw-mb-[32px]'>
                                            <img src="/cloud-services/check-icon.svg" className='tw-mr-[16px]' alt="check-icon" />
                                            <div>
                                                <h5 className='mir-h6-semibold tw-mb-[8px] mir-text-neutral-800'>{item.heading}</h5>
                                                <p className='mir-p-rg-regular mir-text-neutral-700'>{item.desc}</p>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </section>

                <div className='tw-mb-[64px]' data-section id="section-4">
                    <HowHelpSection
                        overline={'HOW CAN WE HELP YOU'}
                        heading={<>Why should you choose <span style={{ color: '#1D68F0' }}>MIR Digital Solutions</span> to help migrate to cloud?</>}
                        desc={<>With a robust history in digital transformations, MIR Digital Solutions offers unparalleled expertise in successful implementations. Our experience fuels innovation and enables efficient cloud solutions. <br /><br />
                            When it comes to cloud migration, our seasoned experts prioritize your cloud computing needs, ensuring maximum ROI, agility, and security through best practices and automation.</>}
                        image={'url(/cloud-services/how-help-bg.png) no-repeat'}
                    />
                </div>

                <section>
                    <div className="container mx-auto">
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-[32px] tw-pt-[36px] tw-pb-[64px]" style={{ borderTop: '1px solid var(--neutral-200, #EEEEF2)' }}>
                            <div className='tw-flex tw-items-start tw-justify-start'>
                                <h6 className='tw-mr-[16px] mir-p-rg-medium mir-text-primary-500'>01</h6>
                                <h5 className='mir-h5-semibold mir-text-neutral-800'>Initial Assessment and Strategy</h5>
                            </div>
                            <div className='tw-col-span-2'>
                                <p className='mir-p-rg-regular mir-text-neutral-700 tw-max-w-full md:tw-max-w-[640px]'>We’ll start by assessing your hosted applications on premise based on complexity, speed, portability, and cost of cloud design before providing an optimal cloud solution that best suits your company’s needs.</p>
                            </div>
                        </div>
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-[32px] tw-pt-[36px] tw-pb-[64px]" style={{ borderTop: '1px solid var(--neutral-200, #EEEEF2)' }}>
                            <div className='tw-flex tw-items-start tw-justify-start'>
                                <h6 className='tw-mr-[16px] mir-p-rg-medium mir-text-primary-500'>02</h6>
                                <h5 className='mir-h5-semibold mir-text-neutral-800'>Design and Architecture</h5>
                            </div>
                            <div className='tw-col-span-2'>
                                <p className='mir-p-rg-regular mir-text-neutral-700 tw-max-w-full md:tw-max-w-[640px]'>Once you’ve known what works the best, our team will provide you with the right documentation, knowledge transfer, blue-prints, and architecture diagrams of the landing zone.</p>
                            </div>
                        </div>
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-[32px] tw-pt-[36px] tw-pb-[64px]" style={{ borderTop: '1px solid var(--neutral-200, #EEEEF2)' }}>
                            <div className='tw-flex tw-items-start tw-justify-start'>
                                <h6 className='tw-mr-[16px] mir-p-rg-medium mir-text-primary-500'>03</h6>
                                <h5 className='mir-h5-semibold mir-text-neutral-800'>Implementation and Testing </h5>
                            </div>
                            <div className='tw-col-span-2'>
                                <p className='mir-p-rg-regular mir-text-neutral-700 tw-max-w-full md:tw-max-w-[640px]'>After finalizing the cloud architecture, we will bring you a structured approach for step-by-step configuration, data migration, syncing and cutover.</p>
                            </div>
                        </div>
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-[32px] tw-pt-[36px] tw-pb-[64px]" style={{ borderTop: '1px solid var(--neutral-200, #EEEEF2)' }}>
                            <div className='tw-flex tw-items-start tw-justify-start'>
                                <h6 className='tw-mr-[16px] mir-p-rg-medium mir-text-primary-500'>04</h6>
                                <h5 className='mir-h5-semibold mir-text-neutral-800'>Monitoring and Optimization</h5>
                            </div>
                            <div className='tw-col-span-2'>
                                <p className='mir-p-rg-regular mir-text-neutral-700 tw-max-w-full md:tw-max-w-[640px]'>We provide 24/7 post Go-Live support. Our Cloud architects and engineers monitor ongoing costs and optimize application architecture for maximum savings with a solid scaling policy.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='tw-pb-[24px]'>
                    <div className="container mx-auto tw-bg-[#001d6c] tw-relative md:tw-rounded-[12px]">
                        <img src="/supply-chain/blue-banner-bg.png" className='tw-absolute tw-z-0 tw-inset-0 tw-w-full tw-h-full tw-rounded-[12px]'
                            alt="Streamline data analysis and reporting with Microsoft Power BI" />
                        <div className="tw-py-[52px] tw-px-[30px] md:tw-py-[52px] md:tw-px-[64px] tw-rounded-[12px] tw-flex tw-items-center tw-flex-wrap tw-justify-between tw-relative tw-z-10">
                            <h3 className='tw-mb-[24px] md:tw-mb-0 tw-max-w-full md:tw-max-w-[717px] mir-h5-semibold mir-text-neutral-100'>Upgrade your apps, elevate your business with Azure or AWS Modernization</h3>
                            <button className='mir-btn-md mir-btn-primary mir-p-rg-medium' onClick={() => { setShowRegionPopup(true) }}>
                                <svg width="20" height="21" className='tw-mr-2' viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.25 3H14.375V2.375C14.375 2.20924 14.3092 2.05027 14.1919 1.93306C14.0747 1.81585 13.9158 1.75 13.75 1.75C13.5842 1.75 13.4253 1.81585 13.3081 1.93306C13.1908 2.05027 13.125 2.20924 13.125 2.375V3H6.875V2.375C6.875 2.20924 6.80915 2.05027 6.69194 1.93306C6.57473 1.81585 6.41576 1.75 6.25 1.75C6.08424 1.75 5.92527 1.81585 5.80806 1.93306C5.69085 2.05027 5.625 2.20924 5.625 2.375V3H3.75C3.41848 3 3.10054 3.1317 2.86612 3.36612C2.6317 3.60054 2.5 3.91848 2.5 4.25V16.75C2.5 17.0815 2.6317 17.3995 2.86612 17.6339C3.10054 17.8683 3.41848 18 3.75 18H16.25C16.5815 18 16.8995 17.8683 17.1339 17.6339C17.3683 17.3995 17.5 17.0815 17.5 16.75V4.25C17.5 3.91848 17.3683 3.60054 17.1339 3.36612C16.8995 3.1317 16.5815 3 16.25 3ZM5.625 4.25V4.875C5.625 5.04076 5.69085 5.19973 5.80806 5.31694C5.92527 5.43415 6.08424 5.5 6.25 5.5C6.41576 5.5 6.57473 5.43415 6.69194 5.31694C6.80915 5.19973 6.875 5.04076 6.875 4.875V4.25H13.125V4.875C13.125 5.04076 13.1908 5.19973 13.3081 5.31694C13.4253 5.43415 13.5842 5.5 13.75 5.5C13.9158 5.5 14.0747 5.43415 14.1919 5.31694C14.3092 5.19973 14.375 5.04076 14.375 4.875V4.25H16.25V6.75H3.75V4.25H5.625ZM16.25 16.75H3.75V8H16.25V16.75Z" fill="white" />
                                </svg>
                                <span className='tw-mr-[12px]'>Get started now</span>
                            </button>
                        </div>
                    </div>
                </section>

                {/* <div className="delivering-wrapper tw-py-[96px]">
                    <div className="container">
                        <h2 className='mx-auto text-center mir-h3-semibold tw-mb-[62px] tw-max-w-full md:tw-max-w-[536px]'>Elevate your business with our Microsoft Partner expertise</h2>
                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-y-[32px] tw-gap-x-[44px]">
                            {
                                [
                                    'An end-to-end digital transformation platform that drives project-based operations from prospect to payment to profit   ',
                                    'An extensive partner ecosystem and go-to-market channels, including AppSource Marketplace, partner-to partner network, and Microsoft sellers   ',
                                    'Dynamics 365 extensibility to unite Finance, Human Resources, Sales, Supply Chain Management, Customer Service, Field Service, and more   ',
                                    'Deep expertise and a rich history with CRM, ERP, HCM, collaboration, productivity, and project management applications   ',
                                    'Complete cloud vision that encompasses Azure, Office 365, and Dynamics 365   ',
                                    'Accelerated application development and flexibility with the Power Platform  ',
                                ].map((item) => {
                                    return <div className="deliver-item d-flex align-items-start justify-content-start">
                                        <img src="/project-operations/blue-check.svg" className='mt-3 me-3' alt="delivering-icon" />
                                        <h3 className='mir-p-rg-regular mt-3' style={{ maxWidth: '450px' }}>{item}</h3>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div> */}

                <div data-section id='section-5'>
                    <SuccessStoriesDynamic
                        successStoriesContent={successStoriesContent}
                    />
                </div>

                <div data-section id='section-6'>
                    <ContactSection />
                </div>

                <Footer />

            </main>
        </>
    )
}
