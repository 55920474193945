import React, { useState } from 'react'
import StickyBanner from '../StickyBanner/StickyBanner'
import Footer from '../Footer/Footer'
import PartnersContent from '../../lib/partners'
import BookNowModal from '../CommonElements/BookNowModal';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './Partners.scss';

export default function Partners() {
    const [showRegionPopup, setShowRegionPopup] = useState(false);
    const navigate = useNavigate();

    return (
        <main>

            <Helmet>
                <meta charSet="utf-8" />
                <title>MIR Partners | MIR Digital Solutions</title>
                <meta name="description" content="MIR Digital Solutions joins with leading global partners to develop innovative solutions that empower your business to thrive" />
                <link rel="canonical" href={`${window.location.origin}/about-us/partners`} />
            </Helmet>

            <StickyBanner />
            <BookNowModal showRegionPopup={showRegionPopup} setShowRegionPopup={setShowRegionPopup} />
            <section className='tw-pt-[80px] tw-pb-[96px] bg-no-repeat' style={{ backgroundImage: 'url(/case-studies/hero-bg.png)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="container mx-auto">
                    <div className='text-center mx-auto tw-max-w-full md:tw-max-w-[600px]'>
                        <h1 className='mir-display-semibold-sm mir-text-neutral-100 tw-mb-[16px]'>Our Partners</h1>
                        <h4 className='mir-text-neutral-400 mir-p-md-regular'>MIR Digital Solutions joins with leading global partners to develop innovative solutions that empower your business to thrive</h4>
                    </div>
                </div>
            </section>

            <section className='tw-pt-[72px] tw-pb-[120px]'>
                <div className="container mx-auto">
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-y-[52px] md:tw-gap-x-[42px]">
                        <div className="tw-col-span-2 tw-grid tw-grid-cols-1 md:tw-grid-cols-1 tw-gap-x-[32px] tw-gap-y-[48px]">
                            {
                                PartnersContent.map((item, index) => {
                                    return <>
                                        <div className={`row align-items-center ${index > 0 ? "partner-card" : ""}`}>
                                            <div className='col-lg-3'>
                                                <img src={item.partnerImage} className='tw-w-2/5 md:tw-w-full tw-mt-6' alt={item.partnerName} />
                                            </div>
                                            <div className='col-lg-9 md:tw-p-5 partner-details'>
                                                <h3 className='mir-text-neutral-800 group-hover:tw-text-[#004BD3] mir-h6-bold md:tw-pr-4'>
                                                    <a className='mir-text-neutral-800 hover:tw-text-[#004BD3]' target='_blank' href={item.partnerLink}>{item.partnerName}</a>
                                                </h3>
                                                <div>{item?.partnerDetails}</div>
                                            </div>
                                        </div>
                                    </>
                                })
                            }
                        </div>
                        <div className='tw-col-span-1 tw-flex tw-justify-end md:tw-justify-start tw-relative md:tw-pl-[52px] tw-border-l-none md:tw-border-l-solid' style={{ borderLeft: '1px solid var(--neutral-200, #EEEEF2)' }}>
                            <div className='text-center tw-h-fit tw-rounded-[4px] tw-sticky tw-top-[79px] mir-bg-neutral-100 tw-p-[36px] tw-max-w-full md:tw-max-w-[280px]'>
                                <h4 className='mir-neutral-800 mir-h4-bold tw-mb-[12px]'>Schedule a meeting today</h4>
                                <p className='mir-neutral-800 mir-p-rg-medium tw-mb-[42px]'>Reach out to explore how we can collaborate and create tailored solutions that drive success for your business</p>
                                <img src="/case-studies/send-email.svg" className='tw-mb-[42px] mx-auto' alt="send-email-icon" />
                                <button onClick={() => { setShowRegionPopup(true) }} className='tw-w-full mir-btn-lg mir-btn-primary mir-p-rg-medium'>Schedule Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </main>
    )
}
